import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from "antd";
import { StyleProvider } from '@ant-design/cssinjs';
import AppContextProvider from "./contexts/AppContextProvider";
import RoutesManager from './routes/RoutesManager';

const antdGlobalStyle = {
  fontFamily: 'Manrope, sans-serif',
  colorPrimary: '#00529C'
}
const inputStyle = {
  controlHeightLG: 55,
  controlHeightSM: 40
}
const componentsStyle = {
  Button: {
    controlHeight: 36,
    borderRadius: 8,
    controlHeightLG: 48,
    colorBorder: '#00529C',
    colorText: '#00529C',
    colorError: '#BF1D08'
  },
  Input: inputStyle,
  Select: inputStyle,
  DatePicker: {
    controlHeightLG: 55
  }
}

function App() {
  return (
    <BrowserRouter>
      <ConfigProvider theme={{ token: antdGlobalStyle, components: componentsStyle }}>
        <StyleProvider hashPriority="high">
          <AppContextProvider>
            <RoutesManager />
          </AppContextProvider>
        </StyleProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;