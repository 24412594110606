import axios from "axios"

export const registerApi = async (request) => {
  return await axios.post("register", request);
}

export const captchaApi = async () => {
  return await axios.get("captcha");
}

export const loginApi = async (request) => {
  return await axios.post("login", request);
}

export const forgotPasswordApi = async (request) => {
  return await axios.post("password/email", request);
}

export const logoutApi = async () => {
  return await axios.post("logout");
}

export const changePasswordApi = async (request) => {
  return await axios.post("password/reset", request);
}

export const historyListApi = async () => {
  return await axios.get("bookings");
}

export const branchListApi = async () => {
  return await axios.get("ukers");
}

export const submitBookingApi = async (request) => {
  return await axios.post("bookings", request);
}

export const submitEformApi = async (request) => {
  return await axios.post("bookings/ref_code", request);
}

export const bookingDetailApi = async (bookingCode) => {
  return await axios.get(`bookings/${bookingCode}`);
}

export const checkInApi = async (request) => {
  return await axios.post(`check_in/${request?.branchCode}`, request);
}

export const queueApi = async (bookingCode) => {
  return await axios.post(`bookings/${bookingCode}`);
}

export const guestTokenApi = async () => {
  return await axios.post('guest/login');
}

export const storeEmailApi = async (request) => {
  return await axios.post('guest/storeEmail', request);
}

export const sendToEmailApi = async () => {
  return await axios.post('guest/sendEmail');
}