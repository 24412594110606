import { useState } from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContextProvider";
import { submitEformApi } from "../../services/api.service";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { allowedInputNumberOnly, allowedInputTextOnly } from "../../utils/helper";
import { ArrowLeftIcon } from "../../components/IconSvg";
import EformImage from "../../assets/images/eform-banner.png";

function RegisterNotification() {
  const navigate = useNavigate();
  const { index } = useParams();
  const { showSuccessMsg, showErrorMsg } = useAppContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const bookingForm = getSessionStorage("booking_form");
  const formIdx = index ? index - 1 : 0;
  const transactions = bookingForm?.transactions || [];

  const onUpdateEforms = () => {
    transactions[formIdx].filled = true;
    saveSessionStorage("booking_form", { ...bookingForm, transactions });
  }

  const onFinish = async (values) => {
    try {
      const request = {
        prefix: transactions[formIdx].code,
        data: values
      }
      setLoading(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      onUpdateEforms();
      navigate(-1);
      showSuccessMsg("Submit form successfully!");
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
      console.log("Error onSubmitEform:", error)
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_header">
        <ArrowLeftIcon onClick={() => navigate(-1)} />
      </div>
      <div className="flex items-center justify-between px-7 pb-4 z-[1]">
        <div className="w-1/2 text-2xl text-white font-bold">{transactions[formIdx]?.label}</div>
        <div className="max-w-[140px] -mt-5">
          <img src={EformImage} alt="banner" />
        </div>
      </div>
      <div className="eform_content">
        <Alert message="Form hanya bisa di isi sekali." type="info" showIcon />
        <div className="text-[#212B36] font-semibold mt-5">{transactions[formIdx]?.label}</div>
        <div className="text-[#637381] text-xs mb-6">Isi form dibawah ini dengan teliti.</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            types: { email: '${label} is not a valid format!' },
            string: {
              min: 'Input must be minimum ${min} character!',
              range: 'Input minimum & maximum must be ${min} character!'
            }
          }}
          onFinish={onFinish}
        >
          <Form.Item
            className="form-item"
            label="Nomor Rekening Nasabah"
            name="nomorRekening"
            normalize={allowedInputNumberOnly}
            rules={[{ required: true, min: 15, max: 15 }]}
          >
            <Input size="large" placeholder="Nomor Rekening Nasabah" maxLength={15} showCount />
          </Form.Item>
          <Form.Item className="form-item" label="Nama Pemilik Rekening" name="namaPemilikRekening" normalize={allowedInputTextOnly} rules={[{ required: true  }]}>
            <Input size="large" placeholder="Nama Pemilik Rekening" maxLength={80} />
          </Form.Item>
          <Form.Item className="form-item" label="Nomor Handphone" name="nomorHandphone" normalize={allowedInputNumberOnly} rules={[{ required: true, min: 8 }]}>
            <Input size="large" placeholder="Nomor Handphone" maxLength={12} showCount />
          </Form.Item>
          <Form.Item className="form-item" label="Alamat Email" name="email" rules={[{ required: true, type: 'email' }]}>
            <Input size="large" placeholder="Alamat Email" maxLength={150} />
          </Form.Item>
          <Form.Item className="form-item" label="SMS Notifikasi" name="isSms" rules={[{ required: true }]}>
            <Select
              size="large"
              placeholder="SMS Notifikasi"
              popupClassName="form-item-select"
              options={[{ value: true, label: 'Ya' }, { value: false, label: 'Tidak' }]}
            />
          </Form.Item>
          <Form.Item className="form-item" label="WhatsApp Notifikasi" name="isWA" rules={[{ required: true }]}>
            <Select
              size="large"
              placeholder="WhatsApp Notifikasi"
              popupClassName="form-item-select"
              options={[{ value: true, label: 'Ya' }, { value: false, label: 'Tidak' }]}
            />
          </Form.Item>
          <Form.Item className="form-item" label="Email Notifikasi" name="isEmail" rules={[{ required: true }]}>
            <Select
              size="large"
              placeholder="Email Notifikasi"
              popupClassName="form-item-select"
              options={[{ value: true, label: 'Ya' }, { value: false, label: 'Tidak' }]}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" className="full-btn mt-9" loading={loading}>
            Submit eForm
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default RegisterNotification;