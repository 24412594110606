import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input } from 'antd';
import { useAppContext } from "../../contexts/AppContextProvider";
import { storeEmailApi, sendToEmailApi } from "../../services/api.service";
import { ArrowLeftIcon } from '../../components/IconSvg';
import ConfirmEmailImg from "../../assets/images/confirmation-email.png";

function BookingEmailForm() {
  const navigate = useNavigate();
  const { code } = useParams();
  const { showSuccessMsg, showErrorMsg } = useAppContext();

  const onFinish = async (values) => {
    try {
      await storeEmailApi(values);
      const response = await sendToEmailApi();
      if (response?.status != 200) throw response?.message;

      showSuccessMsg(response?.message);
      navigate(`/qrcode/${code}`);
    } catch (error) {
      showErrorMsg(error);
      console.log("Error on onForgotPassword:", error);
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_header">
        <ArrowLeftIcon onClick={() => navigate(-1)} />
      </div>
      <div className="base_only_banner">
        <img src={ConfirmEmailImg} alt="banner" />
      </div>
      <div className="booking_email_form">
        <div className="text-[#212B36] font-semibold">Email Confirmation</div>
        <div className="text-[#637381] text-xs mb-[14px]">Kami akan mengirimkan kode booking pada email Anda.</div>
        <Form
          size="small"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            types: { email: '${label} is not a valid format!' }
          }}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
            <Input size="large" placeholder="Input your email address" />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" className="full-btn mt-24">Next</Button>
        </Form>
      </div>
    </div>
  )
}

export default BookingEmailForm;