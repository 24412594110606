import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Checkbox } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
import { useAppContext } from "../../contexts/AppContextProvider";
import { registerApi, captchaApi } from "../../services/api.service";
import { ArrowLeftIcon } from '../../components/IconSvg';
import LogoIcon from "../../assets/icons/logo-icon.png";
import LandingImg from "../../assets/images/landing-banner.png";

function Register() {
  const navigate = useNavigate();
  const { updateStore, showSuccessMsg, showErrorMsg } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");

  useEffect(() => {
    onGetCaptcha();
  }, [])

  const onGetCaptcha = async () => {
    try {
      const response = await captchaApi();
      if (response?.status != 200) throw response?.message;
      setCaptcha(response?.data)
    } catch (error) {
      showErrorMsg(error);
      console.log("Error on onGetCaptcha:", error)
    }
  }

  const onValidateTermAndCondition = (_, value) => {
    return value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement'));
  }

  const onFinish = async (values) => {
    try {
      const request = {
        ...values,
        key: captcha?.key
      }

      setLoading(true);
      const response = await registerApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      navigate('/login');
      showSuccessMsg(response?.message);
    } catch (error) {
      setLoading(false);
      updateStore({ modal: { open: true, message: error }});
      onGetCaptcha();
      console.log("Error on Login:", error);
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_logo_header">
        <ArrowLeftIcon className="base_back_icon" onClick={() => navigate(-1)} />
        <img src={LogoIcon} alt="logo" />
        <span className="base_header_text">BRIQue</span>
      </div>
      <div className="base_only_banner">
        <img src={LandingImg} alt="banner" />
      </div>
      <div className="register_form">
        <div className="text-[#212B36] font-semibold">Sign Up</div>
        <div className="text-[#637381] text-xs mb-[14px]">Please complate your data to register a BRIQue account.</div>
        <Form
          size="small"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            types: { email: '${label} is not a valid format!' }
          }}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="Full Name" name="name" rules={[{ required: true }]}>
            <Input size="large" placeholder="Input your fullname" />
          </Form.Item>
          <Form.Item className="form-item" label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
            <Input size="large" placeholder="Input your email address" />
          </Form.Item>
          <Form.Item className="form-item" label="Password" name="password" rules={[{ required: true }]}>
            <Input.Password size="large" placeholder="Input your password" />
          </Form.Item>
          <Form.Item className="form-item" label="Captcha" name="captcha" rules={[{ required: true }]}>
            <Input
              size="large"
              placeholder="Captcha"
              suffix={
                <Fragment>
                  <img src={captcha?.img} className="h-6" />
                  <RetweetOutlined style={{ fontSize: 20, color: '#000000'}} onClick={onGetCaptcha} />
                </Fragment>
              }
            />
          </Form.Item>
          <Form.Item name="term_and_condition" valuePropName="checked" rules={[{ validator: onValidateTermAndCondition }]}>
            <Checkbox className="checkbox-padding" style={{ fontSize: 12, padding: 0 }}>
              <span className="text-[#919EAB] text-xs font-bold">
                I agree to the &nbsp;
                <span className="text-[#33A0FF] underline">Term & Condition</span>
                &nbsp; and &nbsp;
                <span className="text-[#33A0FF] underline">Privacy Policy</span>
              </span>
            </Checkbox>
          </Form.Item>
          <div className="w-full max-w-[256px] mx-auto mt-8">
            <Button htmlType="submit" type="primary" size="large" className="full-btn" loading={loading}>Sign Up</Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Register;