import { useEffect, useState } from "react";
import { Button, Empty, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContextProvider";
import { getToken, saveSessionStorage } from './../../services/jwt.service';
import { historyListApi } from "../../services/api.service";
import { clearActiveSessionStorage } from "../../utils/helper";
import { HomeIcon, ClockIcon, UserIcon } from "../../components/IconSvg";
import NotFoundImage from "../../assets/images/not-found-image.png";

function History() {
  const navigate = useNavigate();
  const { code } = useParams();
  const { showErrorMsg } = useAppContext();
  const [isAuthenticated, setIsAuthenticated] = useState(!!getToken());
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    const getHistoryList = async () => {
      try {
        setLoading(true);
        const response = await historyListApi();
        setLoading(false);
        setHistoryList(response?.data || []);
      } catch (error) {
        setLoading(false);
        setHistoryList([]);
        setIsAuthenticated(!error?.includes('expired'));
        showErrorMsg(error);
        console.log("Error on getHistoryList:", error);
      }
    }
    if (isAuthenticated) getHistoryList();
    clearActiveSessionStorage();
  }, [])

  const onHistoryDetail = (data) => {
    if (data?.status === "Selesai") {
      return navigate(`/queue/${data?.kode_booking}`);
    }
    saveSessionStorage("booking_form", { bookingCode: data?.kode_booking, branchCode: code });
    if (!code) return navigate('/qrcode');
    navigate(`/opencam/${code}`);
  }

  function renderCard() {
    if (loading) {
      return (
        <div className="h-full grid place-items-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} />
        </div>
      )
    }

    if (!historyList?.length) {
      return (
        <div className="h-full grid place-items-center">
          <Empty />
        </div>
      )
    };

    return historyList.map((data, index) => (
      <div key={index} className="bg-white rounded-2xl p-[20px_22px] mb-5 shadow-lg" onClick={() => onHistoryDetail(data)}>
        <div className="text-[#212B36] text-sm font-semibold mb-2">{data?.lokasi_transaksi}</div>
        <div className="text-[#212B36] text-sm font-semibold mb-3">{data?.tanggal_transaksi}</div>
        <div className="flex items-center justify-between">
          <div className="text-[#637381] text-xs">Status Reservasi</div>
          {data?.status === "Aktif" &&
            <div className="bg-[#57ADC8] rounded-2xl px-2 py-[1px] text-white text-[11px]">{data?.status}</div>
          }
          {data?.status === "Selesai" &&
            <div className="bg-[#108043] rounded-2xl px-2 py-[1px] text-white text-[11px]">{data?.status}</div>
          }
          {data?.status === "Expired" &&
            <div className="bg-[#BF1D08] rounded-2xl px-2 py-[1px] text-white text-[11px]">{data?.status}</div>
          }
        </div>
      </div>
    ))
  }

  return (
    <div className="base_container">
      <div className="text-white text-2xl text-center font-bold mt-14 mb-16">History</div>
      {!isAuthenticated &&
        <div className="history_content items-center justify-center">
          <div className="max-w-[240px]">
            <img src={NotFoundImage} alt="notfound" />
          </div>
          <div className="max-w-[258px] text-[#212B36] text-sm text-center font-semibold my-6">
            Login atau Registrasi terlebih dahulu untuk menampilkan History
          </div>
          <div className="w-full max-w-[313px]">
            <Button type="primary" size="large" className="full-btn" onClick={() => navigate("/login")}>Login/Register</Button>
          </div>
        </div>
      }
      {isAuthenticated &&
        <div className="history_content">
          <div className="text-[#212B36] font-semibold">History Booking</div>
          <div className="text-[#637381] text-xs mb-11">Cklick to see the detail</div>
          <div className="text-[#212B36] text-[13px] font-semibold mb-4">Agustus 2023</div>
          {renderCard()}
        </div>
      }
      <div className="history_footer">
        <div className="cursor-pointer" onClick={() => navigate("/home")}>
          <HomeIcon />
          <div>Home</div>
        </div>
        <div className="text-[#00529C] cursor-pointer" onClick={() => navigate("/history")}>
          <ClockIcon color="#00529C" />
          <div>History</div>
        </div>
        <div className="cursor-pointer" onClick={() => navigate("/profile")}>
          <UserIcon />
          <div>Profile</div>
        </div>
      </div>
    </div>
  )
}

export default History;