import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Checkbox } from 'antd';
import { useAppContext } from "../../contexts/AppContextProvider";
import { loginApi } from "../../services/api.service";
import { destroyGuestToken, getLocalStorage, getSessionStorage, removeLocalStorage, saveLocalStorage, saveSessionStorage, saveToken } from "../../services/jwt.service";
import { ArrowLeftIcon } from '../../components/IconSvg';
import LogoIcon from "../../assets/icons/logo-icon.png";
import LandingImg from "../../assets/images/landing-banner.png";

function Login() {
  const navigate = useNavigate();
  const { showErrorMsg } = useAppContext();
  const [loading, setLoading] = useState(false);
  const loginCredential = getLocalStorage('remember_me') || { remember: false };
  const redirectPath = getSessionStorage('redirect_path');

  const onRememberMe = (request) => {
    if (request?.remember) {
      return saveLocalStorage('remember_me', request);
    }
    removeLocalStorage('remember_me');
  }

  const onFinish = async (request) => {
    try {
      setLoading(true);
      onRememberMe(request);
      const response = await loginApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      saveToken(response?.data?.token);
      destroyGuestToken();
      saveSessionStorage("user_detail", response?.data?.user);
      navigate(redirectPath || '/home');
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
      console.log("Error on Login:", error);
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_logo_header">
        <ArrowLeftIcon className="base_back_icon" onClick={() => navigate('/landing')} />
        <img src={LogoIcon} alt="logo" />
        <span className="base_header_text">BRIQue</span>
      </div>
      <div className="base_only_banner">
        <img src={LandingImg} alt="banner" />
      </div>
      <div className="login_form">
        <div className="text-[#212B36] font-semibold">Sign In</div>
        <div className="text-[#637381] text-xs mb-[14px]">Please enter your email & password.</div>
        <Form
          size="small"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            types: { email: '${label} is not a valid format!' }
          }}
          initialValues={loginCredential}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
            <Input size="large" placeholder="Input your email address" />
          </Form.Item>
          <Form.Item className="form-item" label="Password" name="password" rules={[{ required: true }]}>
            <Input.Password size="large" placeholder="Input your password" />
          </Form.Item>
          <div className="flex items-center justify-between mb-4">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox className="login_form_remember">Remember me</Checkbox>
            </Form.Item>
            <div className="text-[#33A0FF] text-xs font-bold underline cursor-pointer" onClick={() => navigate('/forgot-password')}>
              Forgot password
            </div>
          </div>
          <Form.Item noStyle>
            <div className="w-full max-w-[256px] mb-4 mx-auto">
              <Button type="primary" htmlType="submit" size="large" className="full-btn" loading={loading}>Sign In</Button>
            </div>
          </Form.Item>
        </Form>
        <div className="flex items-center justify-center gap-1 text-sm">
          <span className="text-[#919EAB]">Don’t have account yet?</span>
          <span className="text-[#33A0FF] font-bold underline cursor-pointer" onClick={() => navigate('/register')}>
            Sign up here
          </span>
        </div>
      </div>
    </div>
  )
}

export default Login;