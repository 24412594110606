import { LoadScript, GoogleMap, Marker, Circle } from '@react-google-maps/api';
import MapMarkerImg from "../../assets/images/map-marker.png";
import ActiveMapMarkerImg from "../../assets/images/active-map-marker.png";

function BranchLocationMap(props) {
  function renderCircleRadius() {
    return (
      <Circle
        center={props.centerLocation}
        options={{
          strokeColor: "#57ADC8",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#57ADC8",
          fillOpacity: 0.35,
          clickable: false,
          draggable: false,
          editable: false,
          visible: true,
          radius: props?.radius || 5000, // Radius in meters
        }}
      />
    )
  }

  function renderMarker(sources = []) {
    const iconSize = { width: 27, height: 38 };
    return sources.map((data, index) => {
      const icon = (props?.activeBranch === data.label) ? ActiveMapMarkerImg : MapMarkerImg;
      return (
        <Marker
          key={index}
          position={data?.location || props?.centerLocation}
          icon={{ url: icon, scaledSize: iconSize }}
        />
      )
    })
  }

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_API_KEY_MAP}>
      <GoogleMap
        mapContainerStyle={{ height: 'calc(100% - 91px)', width: '100%' }}
        zoom={12}
        center={props.centerLocation}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          rotateControl: false,
          styles: [{ featureType: 'poi', stylers: [{ visibility: 'off' }] }]
        }}
      >
        <Marker position={props?.centerLocation} />
        {renderMarker(props.locationList)}
        {renderCircleRadius()}
      </GoogleMap>
    </LoadScript>
  )
}

export default BranchLocationMap;