function BackgroundBase() {
  return (
    <div className="base_background">
      <svg width="100%" height="100%" viewBox="0 0 375 812" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.1">
          <path d="M-186.531 794.072C-82.8322 667.576 19.0003 609.634 96.4791 580.398C161.072 556.028 182.54 561.503 234.397 531.895C346.927 467.641 457.324 321.103 408.443 227.25C384.364 181.018 333.821 184.862 252.559 147.195C179.458 113.31 85.2886 46.848 6.1545 -97.7241" stroke="white" strokeMiterlimit={10} />
          <path d="M-167.441 797.862C-73.324 673.378 23.5864 615.182 96.923 585.069C158.644 559.735 179.597 561.23 231.499 529.092C340.379 461.673 445.159 324.366 401.282 231.538C378.685 183.722 330.579 182.72 250.64 141.993C180.263 106.136 88.2885 39.2426 9.61183 -103.004" stroke="white" strokeMiterlimit={10} />
          <path d="M-148.382 801.65C-63.8303 679.179 28.2016 620.674 97.3681 589.729C156.292 563.367 176.639 561.024 228.606 526.283C333.875 455.911 433.218 327.416 394.135 235.808C373.018 186.319 327.365 180.645 248.728 136.782C181.066 99.0351 91.2938 31.6318 13.0823 -108.298" stroke="white" strokeMiterlimit={10} />
          <path d="M-129.35 805.436C-54.3486 684.977 32.8511 626.094 97.8277 594.39C154.008 566.96 173.679 560.893 225.717 523.464C327.431 450.338 421.512 330.269 386.995 240.069C367.388 188.819 324.169 178.641 246.817 131.564C181.852 92.0298 94.2954 24.0151 16.5357 -113.585" stroke="white" strokeMiterlimit={10} />
          <path d="M-110.349 809.219C-44.8845 690.756 37.5214 631.425 98.2581 599.04C151.791 570.494 170.733 560.81 222.825 520.673C321.045 444.973 410.037 332.973 379.857 244.346C361.777 191.248 320.967 176.735 244.9 126.37C182.61 85.1255 97.283 16.4197 19.9922 -118.853" stroke="white" strokeMiterlimit={10} />
          <path d="M-91.379 813.002C-35.4381 696.556 42.2156 636.66 98.6997 603.691C149.646 573.955 167.799 560.827 219.939 517.867C314.745 439.76 398.802 335.511 372.734 248.608C356.226 193.583 317.756 174.915 242.987 121.164C183.338 78.2956 100.27 8.81705 23.4205 -124.143" stroke="white" strokeMiterlimit={10} />
          <path d="M-72.4387 816.783C-26.0156 702.341 46.9122 641.776 99.1411 608.339C147.568 577.337 164.897 560.895 217.058 515.066C308.532 434.692 387.804 337.918 365.618 252.865C350.744 195.845 314.532 173.147 241.079 115.96C184.029 71.5438 103.255 1.20703 26.8585 -129.426" stroke="white" strokeMiterlimit={10} />
          <path d="M-53.5235 820.553C-16.5814 708.117 51.614 646.752 99.5849 612.975C145.55 580.611 162.042 561.006 214.184 512.258C302.416 429.747 377.038 340.199 358.515 257.108C345.346 198.028 311.278 171.466 239.173 110.747C184.679 64.8714 106.235 -6.37913 30.2888 -134.712" stroke="white" strokeMiterlimit={10} />
          <path d="M-34.6475 824.342C-7.17841 713.909 56.2867 651.602 100.025 617.629C143.577 583.788 159.239 561.216 211.306 509.467C296.394 424.922 366.492 342.403 351.418 261.368C340.04 200.164 307.968 169.881 237.262 105.555C185.281 58.2632 109.195 -13.9773 33.7179 -140.011" stroke="white" strokeMiterlimit={10} />
          <path d="M-15.8079 828.117C2.20531 719.683 60.9111 656.287 100.457 622.267C141.62 586.853 156.46 561.452 208.429 506.67C290.459 420.195 356.147 344.532 344.325 265.611C334.834 202.251 304.601 168.356 235.349 100.352C185.823 51.716 112.153 -21.576 37.1243 -145.284" stroke="white" strokeMiterlimit={10} />
          <path d="M3.02002 831.894C11.5942 725.447 65.4892 660.815 100.906 626.904C139.684 589.777 153.774 561.739 205.572 503.873C284.644 415.54 346.025 346.603 337.261 269.854C329.773 204.293 301.184 166.907 233.455 95.1534C186.329 45.2291 115.122 -29.1671 40.5521 -150.568" stroke="white" strokeMiterlimit={10} />
          <path d="M21.8044 835.669C20.9482 731.232 69.9665 665.185 101.345 631.541C137.703 592.561 151.151 562.059 202.712 501.081C278.918 410.948 336.082 348.632 330.197 274.093C324.846 206.302 297.686 165.519 231.557 89.9555C186.772 38.7852 118.073 -36.7577 43.9565 -155.852" stroke="white" strokeMiterlimit={10} />
          <path d="M40.5595 839.444C30.2936 737.003 74.3262 669.409 101.787 636.175C135.643 595.202 148.589 562.438 199.857 498.288C273.29 406.404 326.317 350.641 323.144 278.325C320.071 208.299 294.11 164.19 229.659 84.7581C187.159 32.3816 121.022 -44.3459 47.3588 -161.138" stroke="white" strokeMiterlimit={10} />
          <path d="M59.2798 843.216C39.6246 742.769 78.5471 673.502 102.226 640.807C133.447 597.699 146.106 562.838 197.002 495.503C267.749 401.904 316.7 352.646 316.103 282.562C315.474 210.275 290.443 162.919 227.761 79.5667C187.488 26.007 123.957 -51.9342 50.7625 -166.42" stroke="white" strokeMiterlimit={10} />
          <path d="M77.9666 846.987C48.9358 748.534 82.6053 677.494 102.661 645.447C131.05 600.08 143.694 563.275 194.155 492.718C262.302 397.428 307.257 354.669 309.071 286.794C311.062 212.264 286.686 161.69 225.858 74.3742C187.75 19.6669 126.889 -59.5126 54.1339 -171.701" stroke="white" strokeMiterlimit={10} />
          <path d="M96.6237 850.758C58.2387 754.285 86.4891 681.42 103.103 650.065C128.393 602.339 141.366 563.717 191.314 489.921C256.952 392.966 297.944 356.7 302.058 291.01C306.859 214.259 282.847 160.49 223.98 69.1726C187.978 13.3247 129.832 -67.1034 57.532 -176.992" stroke="white" strokeMiterlimit={10} />
          <path d="M115.249 854.515C67.5219 760.047 99.6929 687.079 103.549 654.68C109.853 601.686 139.119 564.173 188.483 487.122C251.672 388.501 288.755 358.78 295.051 295.232C302.88 216.3 278.89 159.322 222.092 63.9793C188.149 7.00529 132.759 -74.6849 60.922 -182.276" stroke="white" strokeMiterlimit={10} />
          <path d="M134.511 858.847C77.3632 766.317 102.302 690.743 104.614 659.762C108.667 605.464 137.632 565.067 186.432 484.708C247.385 384.351 280.637 361.223 289.033 299.713C300.12 218.615 275.805 158.366 221.063 58.9192C189.066 0.787015 136.384 -82.2097 64.8649 -187.564" stroke="white" strokeMiterlimit={10} />
          <path d="M153.983 863.323C87.3638 772.719 104.657 694.816 105.835 664.96C108.038 609.228 136.385 566.072 184.58 482.379C243.359 380.279 272.802 363.817 283.25 304.274C297.849 221.073 272.85 157.481 220.26 53.9014C190.153 -5.39806 140.209 -89.7172 68.9897 -192.845" stroke="white" strokeMiterlimit={10} />
          <path d="M173.469 867.798C97.3635 779.124 102.377 699.196 107.03 670.156C116.213 612.958 135.172 567.074 182.67 480.043C239.341 376.18 264.958 366.496 277.389 308.831C295.748 223.629 269.71 156.598 219.386 48.8686C191.132 -11.6083 143.989 -97.2354 73.0645 -198.135" stroke="white" strokeMiterlimit={10} />
          <path d="M192.973 872.273C107.362 785.531 106.196 704.154 108.201 675.359C112.326 616.159 133.996 568.085 180.725 477.709C235.337 372.07 257.108 369.286 271.478 313.398C293.872 226.312 266.46 155.722 218.493 43.849C192.051 -17.8189 147.758 -104.743 77.1438 -203.417" stroke="white" strokeMiterlimit={10} />
          <path d="M212.494 876.748C117.358 791.937 109.927 709.352 109.348 680.561C108.113 619.639 132.859 569.092 178.744 475.367C231.343 367.927 249.233 372.185 265.517 317.962C292.183 229.135 263.051 154.839 217.556 38.8174C192.908 -24.05 151.495 -112.261 81.1951 -208.704" stroke="white" strokeMiterlimit={10} />
        </g>
      </svg>
    </div>
  )
}

export default BackgroundBase;