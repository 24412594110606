import React, { createContext, useContext, useState } from 'react';
import { message } from 'antd';

const AppContext = createContext();

function AppContextProvider({ children }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [store, setStore] = useState({});

  const updateStore = (newStore) => {
    setStore(newStore || {});
  }

  const showSuccessMsg = (msg) => {
    if (!msg && typeof msg !== 'string') return;
    messageApi.success(msg);
  }

  const showWarningMsg = (msg) => {
    if (!msg && typeof msg !== 'string') return;
    messageApi.warning(msg);
  }

  const showErrorMsg = (msg) => {
    if (!msg && typeof msg !== 'string') return;
    messageApi.error(msg);
  }

  return (
    <AppContext.Provider value={{ store, updateStore, messageApi, showSuccessMsg, showWarningMsg, showErrorMsg }}>
      {children}
      {contextHolder}
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
export default AppContextProvider;
