import { useEffect, useState } from "react";
import { Alert, Button, Form, Input, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { useAppContext } from "../../contexts/AppContextProvider";
import { submitEformApi } from "../../services/api.service";
import { getSessionStorage, getToken, saveSessionStorage } from '../../services/jwt.service';
import { ArrowLeftIcon, CircleCheckIcon, CircleUncheckIcon, EditPencilIcon } from "../../components/IconSvg";
import FinancialJson from "../../assets/json/financial-form.json";
import NonFinancialJson from "../../assets/json/non-financial-form.json";
import FinancialCardImg from "../../assets/images/financial-card-image.png";
import NonFinancialCardImg from "../../assets/images/nonfinancial-card-image.png";

const bookingType = {
  "financial": {
    text: "Financial Services",
    image: FinancialCardImg,
    json: FinancialJson,
    limitForm: 10
  },
  "non-financial": {
    text: "Non Financial Services",
    image: NonFinancialCardImg,
    json: NonFinancialJson,
    limitForm: 4
  }
}

function ListBookingForm() {
  const navigate = useNavigate();
  const { type } = useParams();
  const { showSuccessMsg, showErrorMsg } = useAppContext();
  const [openModal, setOpenModal] = useState(false);
  const [openEformModal, setOpenEformModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const bookingForm = getSessionStorage("booking_form");

  const headerText = bookingType?.[type]?.text || "";
  const bannerImage = bookingType?.[type]?.image || "";
  const limitForm = bookingType?.[type]?.limitForm || "";

  useEffect(() => {
    const sources = bookingForm?.transactions || [];
    if (sources?.length) setTransactions(sources);
  }, [])

  const onAddedTrxItem = (data, index) => {
    if (!data && typeof index !== "number") return;
    
    if (!limitForm) return;
    if (transactions?.length >= limitForm) {
      showErrorMsg("You can't add more form!");
      return;
    }

    let newList = [...transactions];
    newList.splice(index, 0, { ...data, isParent: false, filled: false });
    setTransactions(newList);
    saveSessionStorage("booking_form", { ...bookingForm, transactions: newList });
  }

  const onRemoveTrxItem = (index) => {
    if (typeof index !== "number") return;
    let newList = [...transactions];
    newList.splice(index, 1);
    setTransactions(newList);
    saveSessionStorage("booking_form", { ...bookingForm, transactions: newList });
  }

  const onConfirmEmail = (hasEmail) => {
    if (hasEmail) {
      navigate(`/booking/email/${bookingForm?.bookingCode}`);
      return;
    }
    navigate(`/qrcode/${bookingForm?.bookingCode}`);
  }

  const onSubmitForm = () => {
    const isNotFilledForm = transactions.some(data => !data.filled);
    if (isNotFilledForm && transactions?.length < 5) {
      showErrorMsg("Please fill all form!");
      return;
    }
    if (transactions?.length >= 5) {
      setOpenEformModal(true);
      return;
    }
    if (!getToken()) {
      setOpenModal(true);
      return;
    }
    navigate(`/qrcode/${bookingForm?.bookingCode}`);
  }

  const onSubmitSpecialForm = async (values) => {
    try {
      const request = {
        prefix: "UNK99",
        data: values
      }
      setLoadingForm(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response?.message;

      setLoadingForm(false);
      setOpenEformModal(false);
      showSuccessMsg("Submit form successfully!");

      if (!getToken()) {
        setOpenModal(true);
        return;
      }
      navigate(`/qrcode/${bookingForm?.bookingCode}`);
    } catch (error) {
      setLoadingForm(false);
      showErrorMsg(error);
      console.log("Error onSubmitSpecialForm:", error)
    }
  }

  function renderEformList() {
    return transactions?.map((data, index) => {
      return (
        <div
          key={index}
          className="relative flex items-center gap-4 bg-white rounded-lg border-[1px] border-[#C4CDD5] p-[14px_22px]"
          onClick={() => {
            if (data?.filled || transactions?.length >= 5) return;
            navigate(`${data?.path}/${index + 1}`);
          }}
        >
          <div className="absolute -left-2">
            {data?.isParent &&
              <PlusCircleFilled
                style={{ color: "#00529C", fontSize: 20 }}
                onClick={(e) => { e.stopPropagation(); onAddedTrxItem(data, index + 1); }}
              />
            }
            {!data?.isParent &&
              <MinusCircleFilled
                style={{ color: "#212B36", fontSize: 20 }}
                onClick={(e) => { e.stopPropagation(); onRemoveTrxItem(index); }}
              />
            }
          </div>
          {data?.filled ? <CircleCheckIcon /> : <CircleUncheckIcon />}
          <div className="flex flex-col gap-1 mr-auto">
            <div className="flex items-center gap-[6px]">
              <div className="text-sm font-semibold text-[#212B36]">{data?.label}</div>
              <div className="text-[10px] font-semibold text-[#33A0FF] whitespace-nowrap">5 menit</div>
            </div>
            {data?.filled &&
              <div className="w-fit bg-[#E3F1DF] text-[10px] font-bold text-[#108043] rounded-full px-2">Formulir Telah Terisi</div>
            }
            {!data?.filled &&
              <div className="w-fit bg-[#F4F6F8] text-[10px]  font-bold text-[#919EAB] rounded-full px-2">Formulir Belum Terisi</div>
            }
          </div>
          {(!data?.filled && transactions?.length < 5) && <EditPencilIcon />}
        </div>
      )
    })
  }

  return (
    <div className="base_container">
      <div className="base_back_header">
        <ArrowLeftIcon onClick={() => navigate(-1)} />
      </div>
      <div className="base_banner_with_text">
        <div className="text-2xl text-white font-bold">{headerText}</div>
        <div className="max-w-[200px] -mt-5">
          <img src={bannerImage} alt="banner" />
        </div>
      </div>
      <div className="listbooking_form_content">
        <div className="text-[#212B36] font-semibold">Pengisian Formulir</div>
        <div className="text-[#637381] text-xs mb-6">Isi formulir dibawah ini untuk mempercepat layanan</div>
        {transactions?.length >= 5 &&
          <Alert message="5 or more eForm. You can go next instead." type="warning" className="!mb-3" showIcon />
        }
        <div className="flex flex-col gap-5">
          {renderEformList()}
        </div>
      </div>
      <div className="fixed max-w-[425px] bottom-0 w-full bg-white px-7 py-4 drop-shadow-md">
        <div className="flex items-center justify-between mb-3">
          <div className="text-[#454F5B] text-sm font-semibold">Total Transaksi</div>
          <div className="text-sm font-semibold text-[#33A0FF]">{transactions?.length} Item</div>
        </div>
        <div className="flex items-center justify-between mb-3">
          <div className="text-[#454F5B] text-sm font-semibold">Total Waktu Layanan</div>
          <div className="text-sm font-semibold text-[#33A0FF]">{transactions?.length * 5} Menit</div>
        </div>
        <Button type="primary" size="large" className="full-btn" onClick={onSubmitForm}>Next</Button>
      </div>
      <Modal
        open={openModal}
        width={267}
        closeIcon={null}
        title={null}
        footer={null}
        centered
      >
        <div className="flex flex-col items-center">
          <div className="text-[#191919] text-sm font-semibold mt-4 mb-10">Apakah Anda Memiliki Email?</div>
          <div className="flex items-center gap-3 mb-4">
            <Button type="primary" onClick={() => onConfirmEmail(true)}>Yes</Button>
            <Button className="orange-btn" onClick={() => onConfirmEmail(false)}>No</Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openEformModal}
        width={320}
        closeIcon={null}
        title={null}
        footer={null}
        centered
        onCancel={() => setOpenEformModal(false)}
      >
        <Form
          size="small"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            pattern: { mismatch: 'Input must be a alphabet!' }
          }}
          onFinish={onSubmitSpecialForm}
        >
          <Form.Item className="form-item" label="Nama Nasabah" name="nama" rules={[{ required: true, pattern: /^[a-zA-Z\s]*$/ }]}>
            <Input size="large" placeholder="Nama Nasabah" maxLength={80} />
          </Form.Item>
          <Button htmlType="submit" type="primary" size="middle" className="full-btn" loading={loadingForm}>Submit eForm</Button>
        </Form>
      </Modal>
    </div>
  )
}

export default ListBookingForm;