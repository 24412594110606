export const branchListMap = (list = []) => {
  let newArr = [];
  list.forEach((data) => {
    if (data?.latitude && data?.longitude) {
      newArr.push({
        id: data?.id,
        label: data?.branch_name,
        value: data?.branch_name,
        code: data?.branch_code,
        address: data?.alamat,
        bookQueue: data?.booking_total,
        queueNumber: data?.antrian_total,
        location: { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }
      });
    }
  })
  return newArr;
}