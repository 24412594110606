import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { clearActiveSessionStorage, getNickName } from './../../utils/helper';
import HomeBannerImg from "../../assets/images/home-banner.png";
import FinancialCardImg from "../../assets/images/financial-card-image.png";
import NonFinancialCardImg from "../../assets/images/nonfinancial-card-image.png";
import { HomeIcon, ClockIcon, UserIcon } from "../../components/IconSvg";

function Home() {
  const navigate = useNavigate();
  const userDetail = getSessionStorage('user_detail');

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        saveSessionStorage("user_location", { latitude, longitude });
      });
    }
    clearActiveSessionStorage();
  }, []);

  const onClickBookingType = (type) => {
    navigate(`/booking/${type}`);
  }

  return (
    <div className="base_container">
      <div className="home_header">
        <div className="text-white text-3xl font-bold">Hello, {getNickName(userDetail?.name || "Guest")}</div>
        <div className="max-w-[232px]">
          <img src={HomeBannerImg} alt="banner" height="100%" />
        </div>
      </div>
      <div className="home_content">
        <div className="text-[#212B36] font-semibold">Choose Services</div>
        <div className="text-[#637381] text-xs mb-7">Choose the service that you will reserve</div>
        <div className="home_content_financial_card bg-[#00529C] cursor-pointer" onClick={() => onClickBookingType("financial")}>
          <div className="flex flex-col flex-1">
            <div className="text-white font-semibold">Financial</div>
            <div className="text-white text-xs">Financial Services</div>
            <div className="home_content_choose">Choose</div>
          </div>
          <div className="home_content_card_img">
            <img src={FinancialCardImg} alt="card-banner" />
          </div>
        </div>
        <div className="home_content_nonfinancial_card bg-[#33A0FF] cursor-pointer" onClick={() => onClickBookingType("non-financial")}>
          <div className="flex flex-col flex-1">
            <div className="text-white font-semibold">Non Financial</div>
            <div className="text-white text-xs">Non Financial Services</div>
            <div className="home_content_choose">Choose</div>
          </div>
          <div className="home_content_card_img">
            <img src={NonFinancialCardImg} alt="card-banner" />
          </div>
        </div>
      </div>
      <div className="home_footer">
        <div className="text-[#00529C] cursor-pointer" onClick={() => navigate("/home")}>
          <HomeIcon color="#00529C" />
          <div>Home</div>
        </div>
        <div className="cursor-pointer" onClick={() => navigate("/history")}>
          <ClockIcon />
          <div>History</div>
        </div>
        <div className="cursor-pointer" onClick={() => navigate("/profile")}>
          <UserIcon />
          <div>Profile</div>
        </div>
      </div>
    </div>
  )
}

export default Home;