import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from 'antd';
import { useAppContext } from "../../contexts/AppContextProvider";
import { changePasswordApi, logoutApi } from "../../services/api.service";
import { destroyToken, getSessionStorage, getToken } from "../../services/jwt.service";
import { ArrowLeftIcon } from "../../components/IconSvg";

function ChangePassword() {
  const navigate = useNavigate();
  const { showSuccessMsg, showErrorMsg } = useAppContext();
  const [loading, setLoading] = useState(false);
  const userDetail = getSessionStorage('user_detail');

  const onLogout = async () => {
    try {
      const response = await logoutApi();
      if (response.status != 200) throw response?.message;

      destroyToken();
      showSuccessMsg(response?.message);
      navigate('/login');
    } catch (error) {
      showErrorMsg(error);
      console.log("Error onLogout:", error);
    }
  }

  const onFinish = async (values) => {
    try {
      const request = {
        ...values,
        token: getToken()
      }

      setLoading(true);
      const response = await changePasswordApi(request);
      if (response.status != 200) throw response?.message;

      setLoading(false);
      onLogout();
      showSuccessMsg(response?.message);
      navigate('/login');
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
      console.log("Error onLogout:", error);
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_header">
        <ArrowLeftIcon onClick={() => navigate('/profile')} />
      </div>
      <div className="text-white text-2xl text-center font-bold mb-10">Password</div>
      <div className="change_pwd_content">
        <div className="text-[#212B36] font-semibold">Change Password</div>
        <div className="text-[#637381] text-xs mb-8">Fill the field to change password</div>
        <Form
          size="small"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          initialValues={{ email: userDetail?.email }}
          validateMessages={{
            required: '${label} is required!',
            types: { email: '${label} is not a valid format!' }
          }}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="Email" name="email">
            <Input size="large" disabled />
          </Form.Item>
          <Form.Item className="form-item" label="New Password" name="password" rules={[{ required: true }]}>
            <Input.Password size="large" placeholder="Input your new password" />
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Confirm Password"
            name="password_confirmation"
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              })
            ]}
          >
            <Input.Password size="large" placeholder="Input your confirm password" />
          </Form.Item>
          <Form.Item noStyle>
            <div className="w-full mb-4 mt-9">
              <Button type="primary" size="large" htmlType="submit" className="full-btn" loading={loading}>
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ChangePassword;