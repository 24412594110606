import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import BackgroundBase from "../components/BackgroundBase";
import ModalMessage from "../components/ModalMessage";

function Layout() {
  return (
    <Fragment>
      <BackgroundBase />
      <Outlet />
      <ModalMessage />
    </Fragment>
  )
}

export default Layout;