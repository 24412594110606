import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Modal, Rate } from 'antd';
import { useAppContext } from "../contexts/AppContextProvider";
import { getSessionStorage } from "../services/jwt.service";
import LogoIcon from "../assets/icons/logo-icon.png";
import FeedbackImg from "../assets/images/feedback-image.png";
import FeedbackConfirmImg from "../assets/images/feedback-confirm-image.png";
import { ArrowLeftIcon } from "../components/IconSvg";

const { TextArea } = Input;

function FeedbackForm() {
  const navigate = useNavigate();
  const { showSuccessMsg, showErrorMsg } = useAppContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { bookingCode, branchCode } = getSessionStorage("booking_form") || {};
  const { que_number } = getSessionStorage("queue_result") || {};

  const onFinish = async (values) => {
    const request = {
      ...values,
      bookingCode: bookingCode,
      branchCode: branchCode,
      queNumber: que_number
    }
    /* let str = "";
    Object.entries(request).forEach(([key, val]) => {
      str += `  ${key} : ${val}\n`;
    });
    str = "{\n" + str + "}"
    alert(str);
    setShowDialog(true); */

    try {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDialog(true);
        showSuccessMsg("success submit feedback!")
        console.log(request);
      }, 1000)
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
    }
  }

  function renderLabelRate(number, text) {
    return (
      <div className="flex gap-2 text-left">
        <div className="text-[#637381] text-sm">{number}.</div>
        <div className="text-[#637381] text-sm">{text}</div>
      </div>
    )
  }

  function renderLabelText() {
    return (
      <span className="text-[#212B36] text-sm font-semibold">
        Can tell us more <span className="text-[#919EAB]">(optional)</span>
      </span>
    )
  }

  return (
    <div className="base_container">
      <div className="base_back_logo_header">
        <ArrowLeftIcon className="base_back_icon" onClick={() => navigate('/home')} />
        <img src={LogoIcon} alt="logo" />
        <span className="base_header_text">BRIQue</span>
      </div>
      <div className="w-56 mx-auto z-[1]">
        <img src={FeedbackImg} alt="banner" />
      </div>
      <div className="feedback_form">
        <div className="text-[#212B36] font-semibold my-4">Share Your Feedback</div>
        <div className=" max-w-[240px] text-[#637381] text-sm text-center mb-11">Are you satisfied with our service? Tap a star to rate it.</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          initialValues={{
            queueRate: 3,
            serviceRate: 3,
            branchRate: 3
          }}
          requiredMark={false}
          onFinish={onFinish}
        >
          <div className="text-center">
            {renderLabelRate(1, "Seberapa Puas anda terhadap proses antrian dan layanan kami ? (Rating star 1-5)")}
            <Form.Item name="queueRate" noStyle>
              <Rate allowClear={false} className="!text-4xl !mt-4 !mb-5" />
            </Form.Item>
          </div>
          <Form.Item label={renderLabelText()} name="queueRateDetail">
            <TextArea size="large" placeholder="Share your feedback here" autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>

          <div className="text-center">
            {renderLabelRate(2, "Seberapa puas anda terhadap pelayanan Customer Service kami?")}
            <Form.Item name="serviceRate" noStyle>
              <Rate allowClear={false} className="!text-4xl !mt-4 !mb-5" />
            </Form.Item>
          </div>
          <Form.Item label={renderLabelText()} name="serviceRateDetail">
            <TextArea size="large" placeholder="Share your feedback here" autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>

          <div className="text-center">
            {renderLabelRate(3, "Seberapa puas anda terhadap fasilitas pelayanan di Cabang kami?")}
            <Form.Item name="branchRate" noStyle>
              <Rate allowClear={false} className="!text-4xl !mt-4 !mb-5" />
            </Form.Item>
          </div>
          <Form.Item label={renderLabelText()} name="branchRateDetail">
            <TextArea size="large" placeholder="Share your feedback here" autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" className="full-btn mt-9" loading={loading}>
            Send Feedback
          </Button>
        </Form>
      </div>
      <Modal
        open={showDialog}
        width={267}
        closeIcon={null}
        title={null}
        footer={null}
        centered
      >
        <div className="flex flex-col items-center">
          <div className="w-44 z-[1]">
            <img src={FeedbackConfirmImg} alt="" />
          </div>
          <div className="text-[#191919] font-semibold my-3">Thank you!</div>
          <div className="text-[#637381] text-xs text-center mb-6">Feedback from customer like you can make us grow better.</div>
          <Button type="primary" className="full-btn" onClick={() => navigate('/home')}>Back</Button>
        </div>
      </Modal>
    </div>
  )
}

export default FeedbackForm;