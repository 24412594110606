import dayjs from "dayjs";
import { removeSessionStorage } from "../services/jwt.service";

export const getNickName = (fullname = "") => {
  const splitName = fullname.split(" ");
  if (splitName.length >= 2) {
    return splitName[1];
  } else if (splitName.length === 1) {
    return splitName[0];
  }
  return fullname;
}

export const getInitialName = (fullName = "") => {
  const names = fullName.trim().split(' ');

  let initials = '';
  if (names.length >= 1 && names[0].length > 0) {
    initials += names[0][0].toUpperCase();
  }
  if (names.length >= 2 && names[names.length - 1].length > 0) {
    initials += names[names.length - 1][0].toUpperCase();
  }

  return initials;
}

export const generateQueueNumber = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
  const randomTwoDigitNumber = Math.floor(Math.random() * 100).toString().padStart(2, '0');
  return randomLetter + randomTwoDigitNumber;
}

// Function to calculate the distance between two points using the Haversine formula
export const calculateDistance = (location1, location2) => {
  const earthRadiusKm = 6371; // Radius of the Earth in kilometers
  const dLat = ((location2.lat - location1.lat) * Math.PI) / 180;
  const dLng = ((location2.lng - location1.lng) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((location1.lat * Math.PI) / 180) *
    Math.cos((location2.lat * Math.PI) / 180) *
    Math.sin(dLng / 2) *
    Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
};

export const allowedInputTextOnly = (value, prevValue = "") => {
  const regexPattern = /^[a-zA-Z\s]*$/;
  if (!regexPattern.test(value)) return prevValue;
  return value;
};

export const allowedInputNumberOnly = (value, prevValue = "") => {
  const regexPattern = /^(\s*|\d+)$/;
  if (!regexPattern.test(value)) return prevValue;
  return value;
};

export const formatedCurrency = (value, prevValue = "") => {
  const regexPattern = /^(\s*|\d+)$/;
  const newVal = value.replace(/\./g, '');
  if (!regexPattern.test(newVal)) return prevValue;
  return newVal.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatedCardNumber = (value, prevValue = "") => {
  const regexPattern = /^(\s*|\d+)$/;
  const newVal = value.replace(/\-/g, '');
  if (!regexPattern.test(newVal)) return prevValue;
  return newVal.replace(/\B(?=(\d{4})+(?!\d))/g, '-');
};

export const showCountFormatedText = ({ value, count }, maxLength) => {
  let exactCount = count;
  if (value?.includes('.')) {
    exactCount = value?.replace(/\./g, '')?.length || count;
  }
  if (value?.includes('-')) {
    exactCount = value?.replace(/\-/g, '')?.length || count;
  }
  return `${exactCount}/${maxLength}`;
};

export const openOnlyThreeDays = (current) => {
  const today = dayjs().startOf('day');
  const threeDaysAhead = today.add(4, 'day').startOf('day');
  return current && (current < today || current > threeDaysAhead);
};

export const clearActiveSessionStorage = () => {
  removeSessionStorage("booking_form");
  removeSessionStorage("photo_checkin");
  removeSessionStorage("redirect_path");
  removeSessionStorage("queue_result");
}