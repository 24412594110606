import { Button, Modal } from 'antd';
import { useAppContext } from "../contexts/AppContextProvider";
import { CircleCloseIcon } from "../components/IconSvg";

function ModalMessage() {
  const { store, updateStore } = useAppContext();

  const onClose = () => {
    updateStore({ modal: { open: false, message: "" } });
  }

  return (
    <Modal
      open={store?.modal?.open}
      width={267}
      closeIcon={null}
      title={null}
      footer={null}
      centered
    >
      <div className="flex flex-col items-center">
        <CircleCloseIcon />
        <div className="text-[#191919] text-base font-semibold mb-3">
          Failed
        </div>
        <div className="text-[#637381] text-[13px] text-center font-normal mb-6">
          {store?.modal?.message || "Internal server error. Please contact administrator."}
        </div>
        <div className="w-full max-w-[113px]">
          <Button type="primary" danger className="full-btn rounded-lg" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalMessage;