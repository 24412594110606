import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider, QRCode } from 'antd';
import { useAppContext } from "../../contexts/AppContextProvider";
import { bookingDetailApi, checkInApi } from "../../services/api.service";
import { getSessionStorage } from "../../services/jwt.service";
import { ArrowLeftIcon } from "../../components/IconSvg";
import QrCodeImage from "../../assets/images/qrcode-result-image.png";

function QRCodeResult() {
  const navigate = useNavigate();
  const { code } = useParams();
  const { showErrorMsg } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [bookingDetail, setBookingDetail] = useState({});
  const bookingForm = getSessionStorage("booking_form");
  const bookingImage = getSessionStorage("photo_checkin");
  const isBooking = code === bookingForm?.bookingCode;

  useEffect(() => {
    const getBookingDetail = async () => {
      try {
        const response = await bookingDetailApi(bookingForm?.bookingCode || code);
        if (response?.status != 200) throw response?.message;

        setBookingDetail(response?.data || {});
      } catch (error) {
        showErrorMsg(error);
        console.log("Error on getBookingDetail:", error);
      }
    }
    getBookingDetail();
  }, [])

  const onCheckIn = async () => {
    try {
      const request = {
        branchCode: code,
        kode_booking: bookingDetail?.kode_booking,
        foto: bookingImage
      }

      setLoading(true);
      const response = await checkInApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      navigate(`/queue/${response?.data?.kode_booking}`);
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
      console.log("Error on onCheckIn:", error);
    }
  }

  const onActionClick = () => {
    if (!isBooking) return onCheckIn();
    navigate('/history');
  }

  const generateRefCode = (param) => {
    if (Array.isArray(bookingDetail?.referenceCode)) {
      if (param?.qrCode) {
        const refCode = bookingDetail?.referenceCode || [];
        return refCode?.join("|");
      }
      return bookingDetail?.referenceCode?.[0];
    }
    return bookingDetail?.referenceCode;
  }

  return (
    <div className="px-7 py-8">
      <div className="flex">
        <ArrowLeftIcon onClick={() => navigate('/history')} />
      </div>
      <div className="flex items-center z-[1]">
        <div className="w-1/2 text-2xl text-white font-bold">{isBooking ? "Reservasi Berhasil!" : "Detail History"}</div>
        <div className="max-w-[163px] -mt-5">
          <img src={QrCodeImage} alt="banner" />
        </div>
      </div>
      <div className="bg-[#F9FAFB] rounded-2xl p-[20px_22px] -mt-5 mb-5">
        <div className="text-[#212B36] text-sm font-semibold mb-2">{bookingDetail?.lokasi_transaksi || "-"}</div>
        <div className="text-[#212B36] text-sm font-semibold mb-3">{bookingDetail?.tanggal_transaksi || "-"}</div>
        <div className="flex items-center justify-between">
          <div className="text-[#637381] text-xs">Status Reservasi</div>
          <div className="bg-[#57ADC8] rounded-2xl px-2 py-[1px] text-white text-[11px]">{bookingDetail?.status || "-"}</div>
        </div>
      </div>
      <div className="flex flex-col items-center bg-[#F9FAFB] rounded-2xl py-8 mb-5">
        <div className="flex">
          <QRCode value={generateRefCode({ qrCode: true }) || "nodata"} />
        </div>
        <div className="relative w-full flex items-center">
          <div className="absolute -left-[1px] w-[18px] h-9 bg-[#013869] rounded-r-full"></div>
          <Divider dashed style={{ borderWidth: "2px 0 0", borderColor: "#999999" }} />
          <div className="absolute -right-[1px] w-[18px] h-9 bg-[#013869] rounded-l-full"></div>
        </div>
        <div className="text-center mb-3">
          <div className="text-[#637381] text-xs">Kode Reservasi</div>
          <div className="text-[#212B36] font-bold">{bookingDetail?.kode_booking || "-"}</div>
        </div>
        <div className="text-center">
          <div className="text-[#637381] text-xs">No. Referensi</div>
          <div className="text-[#212B36] font-bold">{generateRefCode() || "-"}</div>
        </div>
      </div>
      <Button type="primary" size="large" className="full-btn disabled-btn" loading={loading} disabled={!code} onClick={onActionClick}>
        {isBooking ? "Selesai" : "Check-In"}
      </Button>
    </div>
  )
}

export default QRCodeResult;