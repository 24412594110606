import { useNavigate } from "react-router-dom";
import BriqueLogoImg from "../assets/images/brique-logo-image.png";

function SplashScreen() {
  const navigate = useNavigate();

  setTimeout(() => navigate("/landing"), [1000]);
  return (
    <div className="base_full_dvh grid place-items-center">
      <img src={BriqueLogoImg} alt="brique-logo-image" />
    </div>
  )
}

export default SplashScreen;