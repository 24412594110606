import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from 'antd';
import LogoIcon from "../../assets/icons/logo-icon.png";
import PasswordImg from "../../assets/images/password-banner.png";
import { ArrowLeftIcon } from '../../components/IconSvg';

function ResetPassword() {
  const navigate = useNavigate();

  const onFinish = (request) => {
    console.log(request);
  }

  return (
    <div className="base_container">
      <div className="base_back_logo_header">
        <ArrowLeftIcon className="base_back_icon" onClick={() => navigate(-1)} />
        <img src={LogoIcon} alt="logo" />
        <span className="base_header_text">BRIQue</span>
      </div>
      <div className="base_only_banner">
        <img src={PasswordImg} alt="banner" height="100%" />
      </div>
      <div className="reset_pwd_form">
        <div className="text-[#212B36] font-semibold">Reset Password</div>
        <div className="text-[#637381] text-xs mb-[14px]">Please enter your new password.</div>
        <Form
          size="small"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            types: { email: '${label} is not a valid format!' }
          }}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="New Password" name="newPassword" rules={[{ required: true }]}>
            <Input.Password size="large" placeholder="Input your new password" />
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              })
            ]}
          >
            <Input.Password size="large" placeholder="Input your confirm password" />
          </Form.Item>
          <Form.Item noStyle>
            <div className="w-full max-w-[256px] mb-4 mt-9 mx-auto">
              <Button type="primary" size="large" htmlType="submit" className="full-btn">Submit</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ResetPassword;