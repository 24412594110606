import { useState } from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import writtenNumber from "written-number";
import { useAppContext } from "../../contexts/AppContextProvider";
import { submitEformApi } from "../../services/api.service";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { allowedInputNumberOnly, allowedInputTextOnly, formatedCardNumber, formatedCurrency, showCountFormatedText } from "../../utils/helper";
import { ArrowLeftIcon } from "../../components/IconSvg";
import BankJson from "../../assets/json/bank.json";
import EformImage from "../../assets/images/eform-banner.png";

const { TextArea } = Input;

function Clearing() {
  const navigate = useNavigate();
  const { index } = useParams();
  const { showSuccessMsg, showErrorMsg } = useAppContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const bookingForm = getSessionStorage("booking_form");
  const formIdx = index ? index - 1 : 0;
  const transactions = bookingForm?.transactions || [];

  const onUpdateEforms = () => {
    transactions[formIdx].filled = true;
    saveSessionStorage("booking_form", { ...bookingForm, transactions });
  }

  const onPriceChange = (e) => {
    const value = e.target.value?.replace(/\./g, '');
    const regexPattern = /^(\s*|\d+)$/;
    if (!regexPattern.test(value)) return;

    if (!value) {
      form.setFieldValue('terbilang', '');
      return;
    };
    const numbToWords = writtenNumber(value, { lang: 'id' });
    form.setFieldValue('terbilang', `${numbToWords} rupiah`);
    form.validateFields(["terbilang"]);
  }

  const onFinish = async (values) => {
    try {
      const request = {
        prefix: transactions[formIdx].code,
        data: {
          ...values,
          noKartu: values.noKartu?.replace(/\-/g, '') || "",
          jumlahDikirim: values.jumlahDikirim?.replace(/\./g, '') || "",
        }
      }
      setLoading(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      onUpdateEforms();
      navigate(-1);
      showSuccessMsg("Submit form successfully!");
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
      console.log("Error onSubmitEform:", error)
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_header">
        <ArrowLeftIcon onClick={() => navigate(-1)} />
      </div>
      <div className="flex items-center justify-between px-7 pb-4 z-[1]">
        <div className="w-1/2 text-2xl text-white font-bold">{transactions[formIdx]?.label}</div>
        <div className="max-w-[140px] -mt-5">
          <img src={EformImage} alt="banner" />
        </div>
      </div>
      <div className="eform_content">
        <Alert message="Form hanya bisa di isi sekali." type="info" showIcon />
        <div className="text-[#212B36] font-semibold mt-5">{transactions[formIdx]?.label}</div>
        <div className="text-[#637381] text-xs mb-6">Isi form dibawah ini dengan teliti.</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            string: {
              min: 'Input must be minimum ${min} character!',
              range: 'Input minimum & maximum must be ${min} character!'
            }
          }}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="Bank Tujuan" name="pesertaPenerimaAkhir" rules={[{ required: true }]}>
            <Select size="large" placeholder="Bank Tujuan" popupClassName="form-item-select" options={BankJson} />
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Nomor Rekening Pengirim"
            name="nomorRekening"
            normalize={allowedInputNumberOnly}
            rules={[{ required: true, min: 15, max: 15 }]}
          >
            <Input size="large" placeholder="Nomor Rekening Pengirim" maxLength={15} showCount />
          </Form.Item>
          <Form.Item className="form-item" label="Nama Nasabah Pengirim" name="namaNasabah" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
            <Input size="large" placeholder="Nama Nasabah Pengirim" maxLength={80} />
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Nomor Rekening Tujuan"
            name="nomorRekeningTujuan"
            normalize={allowedInputNumberOnly}
            rules={[{ required: true, min: 15 }]}
          >
            <Input size="large" placeholder="Nomor Rekening Tujuan" maxLength={40} showCount />
          </Form.Item>
          <Form.Item className="form-item" label="Nama Nasabah Penerima" name="namaPenerima" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
            <Input size="large" placeholder="Nama Nasabah Penerima" maxLength={80} />
          </Form.Item>
          <Form.Item className="form-item" label="Alamat Tujuan" name="alamatTujuan" rules={[{ required: true }]}>
            <TextArea size="large" placeholder="Alamat Tujuan" maxLength={150} autoSize />
          </Form.Item>
          <Form.Item className="form-item" label="Cara Penyetoran" name="caraPenyetoran" rules={[{ required: true }]}>
            <Select
              size="large"
              placeholder="Cara Penyetoran"
              popupClassName="form-item-select"
              options={[
                { value: 'Tunai', label: 'Tunai' },
                { value: 'Non Tunai', label: 'Non Tunai' },
                { value: 'Non Tunai (Dengan Kartu)', label: 'Non Tunai (Dengan Kartu)' }
              ]}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.caraPenyetoran !== next.caraPenyetoran}>
            {({ getFieldValue }) => {
              if (getFieldValue('caraPenyetoran') === "Non Tunai") {
                return (
                  <Form.Item
                    className="form-item"
                    label="Nomor Rekening Asal"
                    name="nomorRekeningAsal"
                    normalize={allowedInputNumberOnly}
                    rules={[{ required: true, min: 15, max: 15 }]}
                  >
                    <Input size="large" placeholder="Nomor Rekening Asal" maxLength={15} showCount />
                  </Form.Item>
                )
              }
              if (getFieldValue('caraPenyetoran') === "Non Tunai (Dengan Kartu)") {
                return (
                  <Form.Item
                    className="form-item"
                    label="No Kartu"
                    name="noKartu"
                    normalize={formatedCardNumber}
                    rules={[
                      { required: true },
                      { min: 19, message: "Input minimum & maximum must be 16 character!" }
                    ]}
                  >
                    <Input size="large" placeholder="No Kartu" maxLength={19} showCount={{ formatter: (info) => showCountFormatedText(info, 16) }} />
                  </Form.Item>
                )
              }
            }}
          </Form.Item>
          <Form.Item className="form-item" label="Jumlah Dana" name="jumlahDikirim" normalize={formatedCurrency} rules={[{ required: true }]}>
            <Input
              size="large"
              placeholder="Jumlah Dana"
              maxLength={16}
              showCount={{ formatter: (info) => showCountFormatedText(info, 13) }}
              onChange={onPriceChange}
            />
          </Form.Item>
          <Form.Item className="form-item" label="Terbilang" name="terbilang" rules={[{ required: true }]}>
            <TextArea size="large" placeholder="Terbilang" readOnly autoSize />
          </Form.Item>
          <Form.Item className="form-item" label="Sumber Dana" name="sumberDana" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
            <Input size="large" placeholder="Sumber Dana" />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" className="full-btn mt-9" loading={loading}>
            Submit eForm
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Clearing;