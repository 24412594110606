import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Select, DatePicker, Drawer, Checkbox, Radio } from 'antd';
import dayjs from "dayjs";
import { useAppContext } from "../../contexts/AppContextProvider";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { branchListApi, submitBookingApi } from "../../services/api.service";
import { branchListMap } from "../../utils/mapper";
import { calculateDistance, openOnlyThreeDays } from "../../utils/helper";
import { ArrowLeftIcon, MapMarkerIcon } from "../../components/IconSvg";
import BranchLocationMap from "./BranchLocationMap";
import FinancialJson from "../../assets/json/financial-form.json";
import NonFinancialJson from "../../assets/json/non-financial-form.json";
import FinancialCardImg from "../../assets/images/financial-card-image.png";
import NonFinancialCardImg from "../../assets/images/nonfinancial-card-image.png";

const bookingType = {
  "financial": {
    typeCode: 1,
    text: "Financial Services",
    image: FinancialCardImg,
    json: FinancialJson,
    limitForm: 10
  },
  "non-financial": {
    typeCode: 2,
    text: "Non Financial Services",
    image: NonFinancialCardImg,
    json: NonFinancialJson,
    limitForm: 4
  }
}
const radiusInMeter = 5000;

function BookingForm() {
  const navigate = useNavigate();
  const { type } = useParams();
  const { showSuccessMsg, showErrorMsg } = useAppContext();
  const [form] = Form.useForm();
  const [openInputTrx, setInputTrx] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [openInputLocation, setInputLocation] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [branchCode, setBranchCode] = useState();
  const [branchLocation, setBranchLocation] = useState();
  const [searchLocation, setSearchLocation] = useState();
  const [loadingBooking, setLoadingBooking] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const userLocation = getSessionStorage("user_location");
  const bookingForm = getSessionStorage("booking_form");

  const centerLocation = { lat: userLocation?.latitude || -6.200000, lng: userLocation?.longitude || 106.816666 }
  const typeCode = bookingType?.[type]?.typeCode;
  const headerText = bookingType?.[type]?.text || "";
  const bannerImage = bookingType?.[type]?.image || "";
  const limitForm = bookingType?.[type]?.limitForm || "";
  const trxTypeList = Object.values(bookingType?.[type]?.json || []);

  useEffect(() => {
    const updateNearbyLocations = (locations) => {
      const nearby = locations?.reduce((result, data) => {
        const distance = calculateDistance(centerLocation, data?.location);
        if (distance <= (radiusInMeter / 1000)) {
          result.push({ ...data, distance: parseFloat(distance)?.toFixed(1) });
        }
        return result;
      }, []);
      nearby.sort((a, b) => a.distance - b.distance);
      setLocationList(nearby);
    };

    const getBranchList = async () => {
      try {
        const response = await branchListApi();
        updateNearbyLocations(branchListMap(response?.data));
      } catch (error) {
        console.log("Error on getBranchList:", error);
      }
    }
    getBranchList();
  }, [])

  useEffect(() => {
    if (form && bookingForm) {
      const defaultTrxType = bookingForm?.transactions.reduce((result, data) => {
        if (data.isParent) result.push(data.value);
        return result;
      }, []);
      form.setFieldsValue({
        transaction: defaultTrxType,
        bookingDate: dayjs(bookingForm?.bookingDate),
        location: bookingForm?.location
      });
      setBranchCode(bookingForm?.branchCode);
      setBranchLocation(bookingForm?.location);
      setTransactions(bookingForm?.transactions);
    }
  }, [locationList])

  const onSelectTrxType = (param) => {
    const { transaction, data, index } = param;
    const valueIdx = transaction.indexOf(data.value);
    let currentTrx = [...transaction];
    let detailTrx = [...transactions];
    if (transaction.includes(data?.value)) {
      currentTrx.splice(valueIdx, 1);
      detailTrx.splice(valueIdx, 1);
    } else {
      if (!limitForm) return;
      if (transactions?.length >= limitForm) {
        showErrorMsg("You can't add more form!");
        return;
      }
      currentTrx.splice(index, 0, data?.value);
      detailTrx.splice(index, 0, { ...data, isParent: true, filled: false });
    }
    form.setFieldValue("transaction", currentTrx);
    form.validateFields(["transaction"]);
    setTransactions(detailTrx);
  }

  const onClearTrxType = (value) => {
    const detailTrx = transactions?.filter(data => data.label !== value);
    setTransactions(detailTrx);
  }

  const onSelectLocation = ({ code, value }) => {
    setBranchCode(code);
    setBranchLocation(value);
    setInputLocation(false);
  };

  const onSearchLocation = (value) => {
    setSearchLocation(value);
  };

  const onSubmitMap = () => {
    setOpenMap(false);
    form.setFieldValue("location", branchLocation);
    form.validateFields(["location"]);
  }

  const onFinish = async (values) => {
    try {
      const request = {
        lokasi_transaksi: branchCode,
        tanggal_transaksi: dayjs(values?.bookingDate).format('YYYY-MM-DD'),
        tipe_transaksi: values?.transaction?.join(" | "),
        jenis_transaksi: typeCode
      }

      setLoadingBooking(true);
      const response = await submitBookingApi(request);
      if (response?.status != 200) throw response?.message;

      setLoadingBooking(false);
      saveSessionStorage("booking_form", {
        transactions: transactions,
        bookingDate: values?.bookingDate,
        branchCode: branchCode,
        location: values?.location,
        bookingCode: response?.data?.kode_booking
      });
      navigate(`/eform/${type}`);
      showSuccessMsg(response?.message);
    } catch (error) {
      setLoadingBooking(false);
      showErrorMsg(error);
      console.log("Error onFinish:", error);
    }
  }

  function renderTrxTypeOptions() {
    return trxTypeList.map((data, index) => {
      const transaction = form.getFieldValue("transaction") || [];
      return (
        <div key={index} className="booking_form_trxtype_opt cursor-pointer" onClick={() => onSelectTrxType({ transaction, data, index })}>
          <div className="title">{data.label}</div>
          <Checkbox checked={!!transaction?.includes(data.value)} />
        </div>
      )
    })
  }

  function renderTrxTypeDropdown() {
    return (
      <Fragment>
        {renderTrxTypeOptions()}
        <div className="text-right">
          <Button type="primary" size="middle" onClick={() => setInputTrx(false)}>OK</Button>
        </div>
      </Fragment>
    )
  }

  function renderLocationDropwdown() {
    return locationList.map((data, index) => {
      if (searchLocation && !(data?.label || '')?.toLowerCase()?.includes(searchLocation?.toLowerCase())) return;

      return (
        <div key={index} className="flex items-center gap-[16px] mb-4 cursor-pointer" onClick={() => onSelectLocation(data)}>
          <MapMarkerIcon />
          <div className="flex-1">
            <div className="color-[#454F5B] text-xs font-semibold">{data.label}</div>
            <div className="flex gap-2 color-[#C4CDD5] text-[10px] font-normal">
              <div>{data.distance}&nbsp;KM</div>
              <div>{data.bookQueue} Booking</div>
              <div>{data.queueNumber} Antrian</div>
            </div>
          </div>
          <Radio checked={!!branchLocation?.includes(data.value)} />
        </div>
      )
    })
  }

  return (
    <div className="base_container">
      <div className="base_back_header">
        <ArrowLeftIcon onClick={() => navigate('/home')} />
      </div>
      <div className="base_banner_with_text">
        <div className="text-2xl text-white font-bold">{headerText}</div>
        <div className="max-w-[200px] -mt-5">
          <img src={bannerImage} alt="banner" />
        </div>
      </div>
      <div className="booking_form_content">
        <div className="text-[#212B36] font-semibold">{headerText}</div>
        <div className="text-[#637381] text-xs mb-6">Select transaction, Date, and Location to be reserved</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          initialValues={{ transaction: [] }}
          validateMessages={{ required: '${label} is required!' }}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="Transaction" name="transaction" rules={[{ required: true }]}>
            <Select
              size="large"
              className="select-item"
              popupClassName="select-item-dropdown"
              open={openInputTrx}
              mode="multiple"
              placeholder="Select Transaction"
              maxTagCount="responsive"
              showSearch={false}
              options={trxTypeList}
              dropdownRender={renderTrxTypeDropdown}
              onDropdownVisibleChange={setInputTrx}
              onDeselect={onClearTrxType}
            />
          </Form.Item>
          <Form.Item className="form-item" label="Date" name="bookingDate" rules={[{ required: true }]}>
            <DatePicker
              size="large"
              popupClassName="datepicker-input"
              inputReadOnly
              format="DD MMMM"
              placeholder="Select Date"
              style={{ width: '100%' }}
              popupStyle={{ width: '100%' }}
              getPopupContainer={trigger => trigger.parentElement}
              showToday={false}
              disabledDate={openOnlyThreeDays}
            />
          </Form.Item>
          <Form.Item className="form-item" label="Location" name="location" rules={[{ required: true }]}>
            <Select size="large" placeholder="Select Location" open={false} onClick={() => setOpenMap(true)} />
          </Form.Item>
          <Form.Item noStyle>
            <Button type="primary" htmlType="submit" size="large" className="full-btn my-[72px]" loading={loadingBooking}>
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Drawer
        key="bottom"
        placement="bottom"
        contentWrapperStyle={{ height: 692, maxWidth: 425, margin: "0 auto" }}
        style={{ borderRadius: 15 }}
        bodyStyle={{ padding: "24px 21px" }}
        closable={false}
        open={openMap}
        onClose={() => setOpenMap(false)}
      >
        <div className="base_popup_greyline"></div>
        <div className="booking_form_location">
          <Select
            size="small"
            popupClassName="select-map-dropdown"
            open={openInputLocation}
            className="booking_form_search"
            style={{ width: "100%" }}
            showSearch
            placeholder="Search Location"
            options={locationList}
            value={branchLocation}
            dropdownRender={renderLocationDropwdown}
            onDropdownVisibleChange={setInputLocation}
            onSelect={onSelectLocation}
            onSearch={onSearchLocation}
            onClear={() => setBranchLocation()}
          />
        </div>
        <BranchLocationMap
          activeBranch={branchLocation}
          centerLocation={centerLocation}
          locationList={locationList}
          radius={radiusInMeter}
        />
        <div className="booking_form_popup_action">
          <Button type="text" onClick={() => setOpenMap(false)}>Cancel</Button>
          <Button type="primary" onClick={onSubmitMap}>Done</Button>
        </div>
      </Drawer>
    </div>
  )
}

export default BookingForm;