import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from 'antd';
import { forgotPasswordApi } from "../../services/api.service";
import LogoIcon from "../../assets/icons/logo-icon.png";
import PasswordImg from "../../assets/images/password-banner.png";
import { ArrowLeftIcon } from '../../components/IconSvg';
import { useAppContext } from "../../contexts/AppContextProvider";

function ForgotPassword() {
  const navigate = useNavigate();
  const { showSuccessMsg, showErrorMsg } = useAppContext();

  const onFinish = async (request) => {
    try {
      const response = await forgotPasswordApi(request);
      if (response?.status != 200) throw response?.message;
      showSuccessMsg(response?.message);
    } catch (error) {
      showErrorMsg(error);
      console.log("Error on onForgotPassword:", error);
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_logo_header">
        <ArrowLeftIcon className="base_back_icon" onClick={() => navigate('/login')} />
        <img src={LogoIcon} alt="logo" />
        <span className="base_header_text">BRIQue</span>
      </div>
      <div className="base_only_banner">
        <img src={PasswordImg} alt="banner" />
      </div>
      <div className="forgot_pwd_form">
        <div className="text-[#212B36] font-semibold">Forgot Password</div>
        <div className="text-[#637381] text-xs mb-[14px]">Please enter your email we will send reset link to you.</div>
        <Form
          size="small"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            types: { email: '${label} is not a valid format!' }
          }}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
            <Input size="large" placeholder="Input your email address" />
          </Form.Item>
          <Form.Item noStyle>
            <div className="w-full max-w-[256px] mb-4 mt-7 mx-auto">
              <Button type="primary" htmlType="submit" size="large" className="full-btn">Next</Button>
            </div>
          </Form.Item>
        </Form>
        <div className="flex items-center gap-1 text-sm">
          <span className="text-[#919EAB]">Didn't you receive any email?</span>
          <span className="text-[#33A0FF] font-bold underline cursor-pointer" onClick={() => {}}>Resend Link</span>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;