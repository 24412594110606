import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppContext } from "../../contexts/AppContextProvider";
import { queueApi } from "../../services/api.service";
import { ArrowLeftIcon } from "../../components/IconSvg";
import QueueImage from "../../assets/images/queue-image.png";
import LogoIcon from "../../assets/icons/logo-icon.png";
import { saveSessionStorage } from "../../services/jwt.service";

function QueueResult() {
  const navigate = useNavigate();
  const { code } = useParams();
  const { showErrorMsg } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [queue, setQueue] = useState();

  useEffect(() => {
    const generateQueueNumber = async () => {
      try {
        setLoading(true);
        const response = await queueApi(code);
        if (response?.status != 200) throw response?.message;

        setLoading(false);
        setQueue(response?.data?.que_number);
        saveSessionStorage('queue_result', response?.data);
      } catch (error) {
        setLoading(false);
        showErrorMsg(error);
        console.log("Error on generateQueueNumber:", error);
      }
    }
    generateQueueNumber();
  }, [])

  return (
    <div className="px-7">
      <div className="base_back_logo_header">
        <ArrowLeftIcon className="base_back_icon" onClick={() => navigate('/history')} />
        <img src={LogoIcon} alt="logo" />
        <span className="base_header_text">BRIQue</span>
      </div>
      <div className="text-white text-2xl text-center font-bold mt-14 mb-7">Nomor Antrian</div>
      <div className="flex flex-col items-center bg-[#F9FAFB] rounded-2xl py-8 mb-8">
        <div className="max-w-[217px] flex">
          <img src={QueueImage} alt="banner" />
        </div>
        <div className="relative w-full flex items-center">
          <div className="absolute -left-[1px] w-[18px] h-9 bg-[#013869] rounded-r-full"></div>
          <Divider dashed style={{ borderWidth: "2px 0 0", borderColor: "#999999" }} />
          <div className="absolute -right-[1px] w-[18px] h-9 bg-[#013869] rounded-l-full"></div>
        </div>
        <div className="text-[#212B36] text-xl font-semibold mb-5">Nomor Antrian Anda</div>
        <div className="text-[#33A0FF] text-[64px] font-bold mb-6">
          {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} />}
          {queue}
        </div>
        <div className="text-[#637381] text-sm text-center">Mohon tunggu sampai nomor antrian Anda dipanggil</div>
      </div>
      <Button type="primary" size="large" className="full-btn" onClick={() => navigate('/feedback')}>Give Feedback</Button>
    </div>
  )
}

export default QueueResult;