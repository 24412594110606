export const ArrowLeftIcon = ({ width = 24, height = 24, color = "#FFFFFF", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.57 5.92993L3.5 11.9999L9.57 18.0699" stroke={color} strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.4999 12H3.66992" stroke={color} strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const HomeIcon = ({ width = 24, height = 24, color = "#919EAB", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.0001 8.00001L14.0001 2.74001C13.4501 2.24805 12.738 1.97607 12.0001 1.97607C11.2622 1.97607 10.5501 2.24805 10.0001 2.74001L4.00009 8.00001C3.68246 8.28408 3.42899 8.63256 3.25657 9.02225C3.08414 9.41194 2.99671 9.83389 3.00009 10.26V19C3.00009 19.7957 3.31617 20.5587 3.87877 21.1213C4.44138 21.6839 5.20445 22 6.00009 22H18.0001C18.7957 22 19.5588 21.6839 20.1214 21.1213C20.684 20.5587 21.0001 19.7957 21.0001 19V10.25C21.0021 9.82557 20.9139 9.40555 20.7416 9.01769C20.5692 8.62983 20.3165 8.28296 20.0001 8.00001ZM14.0001 20H10.0001V15C10.0001 14.7348 10.1055 14.4804 10.293 14.2929C10.4805 14.1054 10.7349 14 11.0001 14H13.0001C13.2653 14 13.5197 14.1054 13.7072 14.2929C13.8947 14.4804 14.0001 14.7348 14.0001 15V20ZM19.0001 19C19.0001 19.2652 18.8947 19.5196 18.7072 19.7071C18.5197 19.8946 18.2653 20 18.0001 20H16.0001V15C16.0001 14.2044 15.684 13.4413 15.1214 12.8787C14.5588 12.3161 13.7957 12 13.0001 12H11.0001C10.2044 12 9.44138 12.3161 8.87877 12.8787C8.31616 13.4413 8.00009 14.2044 8.00009 15V20H6.00009C5.73488 20 5.48052 19.8946 5.29299 19.7071C5.10545 19.5196 5.00009 19.2652 5.00009 19V10.25C5.00027 10.108 5.03069 9.9677 5.08931 9.83839C5.14794 9.70907 5.23343 9.59372 5.3401 9.50001L11.3401 4.25001C11.5226 4.08969 11.7572 4.00127 12.0001 4.00127C12.243 4.00127 12.4776 4.08969 12.6601 4.25001L18.6601 9.50001C18.7668 9.59372 18.8523 9.70907 18.9109 9.83839C18.9695 9.9677 18.9999 10.108 19.0001 10.25V19Z"
        fill={color}
      />
    </svg>
  )
}

export const ClockIcon = ({ width = 24, height = 24, color = "#919EAB", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 2C9.43639 2.00731 6.97349 2.99891 5.12 4.77V3C5.12 2.73478 5.01464 2.48043 4.82711 2.29289C4.63957 2.10536 4.38522 2 4.12 2C3.85478 2 3.60043 2.10536 3.41289 2.29289C3.22536 2.48043 3.12 2.73478 3.12 3V7.5C3.12 7.76522 3.22536 8.01957 3.41289 8.20711C3.60043 8.39464 3.85478 8.5 4.12 8.5H8.62C8.88522 8.5 9.13957 8.39464 9.32711 8.20711C9.51464 8.01957 9.62 7.76522 9.62 7.5C9.62 7.23478 9.51464 6.98043 9.32711 6.79289C9.13957 6.60536 8.88522 6.5 8.62 6.5H6.22C7.50578 5.15636 9.21951 4.30265 11.0665 4.08567C12.9136 3.86868 14.7785 4.30198 16.3407 5.31104C17.9028 6.32011 19.0646 7.84191 19.6263 9.61479C20.188 11.3877 20.1145 13.3009 19.4184 15.0254C18.7223 16.7499 17.4472 18.1781 15.8122 19.0643C14.1772 19.9505 12.2845 20.2394 10.4596 19.8813C8.63463 19.5232 6.99147 18.5405 5.81259 17.1022C4.63372 15.6638 3.99279 13.8597 4 12C4 11.7348 3.89464 11.4804 3.70711 11.2929C3.51957 11.1054 3.26522 11 3 11C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78412 19.2002 6.3459 20.4819 8.17317 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8079 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2ZM12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13H14C14.2652 13 14.5196 12.8946 14.7071 12.7071C14.8946 12.5196 15 12.2652 15 12C15 11.7348 14.8946 11.4804 14.7071 11.2929C14.5196 11.1054 14.2652 11 14 11H13V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z"
        fill={color}
      />
    </svg>
  )
}

export const UserIcon = ({ width = 24, height = 24, color = "#919EAB", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 2C10.0605 2.00369 8.16393 2.57131 6.54128 3.63374C4.91862 4.69617 3.63994 6.20754 2.86099 7.98377C2.08204 9.76 1.83643 11.7244 2.15408 13.6378C2.47173 15.5511 3.33893 17.3308 4.65005 18.76C5.58647 19.775 6.72299 20.5851 7.98799 21.1392C9.25298 21.6933 10.619 21.9793 12 21.9793C13.3811 21.9793 14.7471 21.6933 16.0121 21.1392C17.2771 20.5851 18.4136 19.775 19.35 18.76C20.6612 17.3308 21.5284 15.5511 21.846 13.6378C22.1637 11.7244 21.9181 9.76 21.1391 7.98377C20.3602 6.20754 19.0815 4.69617 17.4588 3.63374C15.8362 2.57131 13.9396 2.00369 12 2ZM12 20C9.92851 19.9969 7.93896 19.1903 6.45005 17.75C6.90209 16.6495 7.67108 15.7083 8.6593 15.0459C9.64752 14.3835 10.8104 14.0298 12 14.0298C13.1897 14.0298 14.3526 14.3835 15.3408 15.0459C16.329 15.7083 17.098 16.6495 17.55 17.75C16.0611 19.1903 14.0716 19.9969 12 20ZM10 10C10 9.60444 10.1173 9.21776 10.3371 8.88886C10.5569 8.55996 10.8692 8.30362 11.2347 8.15224C11.6001 8.00087 12.0023 7.96126 12.3902 8.03843C12.7782 8.1156 13.1346 8.30608 13.4143 8.58579C13.694 8.86549 13.8844 9.22186 13.9616 9.60982C14.0388 9.99778 13.9992 10.3999 13.8478 10.7654C13.6964 11.1308 13.4401 11.4432 13.1112 11.6629C12.7823 11.8827 12.3956 12 12 12C11.4696 12 10.9609 11.7893 10.5858 11.4142C10.2108 11.0391 10 10.5304 10 10ZM18.91 16C18.0166 14.4718 16.6415 13.283 15 12.62C15.5092 12.0427 15.841 11.3307 15.9555 10.5694C16.0701 9.80822 15.9625 9.03011 15.6458 8.3285C15.3291 7.62688 14.8166 7.03156 14.17 6.61397C13.5233 6.19637 12.7698 5.97425 12 5.97425C11.2303 5.97425 10.4768 6.19637 9.83014 6.61397C9.18346 7.03156 8.67102 7.62688 8.3543 8.3285C8.03758 9.03011 7.93004 9.80822 8.04458 10.5694C8.15912 11.3307 8.49088 12.0427 9.00005 12.62C7.35865 13.283 5.98352 14.4718 5.09005 16C4.37799 14.7871 4.00177 13.4065 4.00005 12C4.00005 9.87827 4.8429 7.84344 6.34319 6.34315C7.84349 4.84285 9.87832 4 12 4C14.1218 4 16.1566 4.84285 17.6569 6.34315C19.1572 7.84344 20 9.87827 20 12C19.9983 13.4065 19.6221 14.7871 18.91 16Z"
        fill={color}
      />
    </svg>
  )
}

export const MapMarkerIcon = ({ width = 24, height = 24, color = "#00529C", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 4C10.4087 4 8.88258 4.67428 7.75736 5.87451C6.63214 7.07474 6 8.7026 6 10.4C6 14.72 11.2875 19.5999 11.5125 19.8079C11.6483 19.9319 11.8212 20 12 20C12.1788 20 12.3517 19.9319 12.4875 19.8079C12.75 19.5999 18 14.72 18 10.4C18 8.7026 17.3679 7.07474 16.2426 5.87451C15.1174 4.67428 13.5913 4 12 4ZM12 18.12C10.4025 16.52 7.5 13.072 7.5 10.4C7.5 9.12694 7.97411 7.90605 8.81802 7.00588C9.66193 6.10571 10.8065 5.59999 12 5.59999C13.1935 5.59999 14.3381 6.10571 15.182 7.00588C16.0259 7.90605 16.5 9.12694 16.5 10.4C16.5 13.072 13.5975 16.528 12 18.12ZM12 7.19999C11.4067 7.19999 10.8266 7.38767 10.3333 7.73928C9.83994 8.0909 9.45542 8.59067 9.22836 9.1754C9.0013 9.76012 8.94189 10.4035 9.05764 11.0243C9.1734 11.645 9.45912 12.2152 9.87868 12.6627C10.2982 13.1102 10.8328 13.415 11.4147 13.5385C11.9967 13.662 12.5999 13.5986 13.1481 13.3564C13.6962 13.1142 14.1648 12.704 14.4944 12.1778C14.8241 11.6516 15 11.0329 15 10.4C15 9.55129 14.6839 8.73736 14.1213 8.13724C13.5587 7.53713 12.7956 7.19999 12 7.19999ZM12 12C11.7033 12 11.4133 11.9061 11.1666 11.7303C10.92 11.5545 10.7277 11.3046 10.6142 11.0123C10.5006 10.7199 10.4709 10.3982 10.5288 10.0878C10.5867 9.77747 10.7296 9.49238 10.9393 9.26861C11.1491 9.04485 11.4164 8.89246 11.7074 8.83073C11.9983 8.76899 12.2999 8.80068 12.574 8.92178C12.8481 9.04288 13.0824 9.24795 13.2472 9.51107C13.412 9.77419 13.5 10.0835 13.5 10.4C13.5 10.8243 13.342 11.2313 13.0607 11.5313C12.7794 11.8314 12.3978 12 12 12Z"
        fill={color}
      />
    </svg>
  )
}


export const EditPencilIcon = ({ width = 24, height = 24, color = "#00529C", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
        fill={color}
      />
    </svg>
  )
}

export const CircleUncheckIcon = ({ width = 30, height = 30, color = "#C4CDD5", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={15} cy="15.3999" r="14.5" stroke={color} />
    </svg>
  )
}

export const CircleCheckIcon = ({ width = 30, height = 30, color = "#82C364", borderColor = "#C4E2B6", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={15} cy={15} r={15} fill="url(#paint0_radial_397_6186)" />
      <path d="M12.6001 22C12.495 22.0001 12.391 21.9775 12.2939 21.9335C12.1969 21.8895 12.1087 21.825 12.0345 21.7436L7.23429 16.4937C7.16001 16.4125 7.10109 16.316 7.06089 16.2099C7.02069 16.1038 7 15.99 7 15.8751C7 15.7602 7.02069 15.6465 7.06089 15.5403C7.10109 15.4342 7.16001 15.3377 7.23429 15.2565C7.30857 15.1753 7.39675 15.1108 7.4938 15.0669C7.59085 15.0229 7.69487 15.0003 7.79991 15.0003C7.90496 15.0003 8.00898 15.0229 8.10603 15.0669C8.20308 15.1108 8.29126 15.1753 8.36554 15.2565L12.5545 19.8379L21.5933 8.30563C21.7312 8.12949 21.9275 8.02049 22.1389 8.00261C22.3503 7.98472 22.5595 8.05941 22.7206 8.21025C22.8816 8.36109 22.9813 8.57572 22.9976 8.80693C23.014 9.03813 22.9457 9.26697 22.8078 9.4431L13.2073 21.6929C13.1357 21.7847 13.0475 21.8593 12.9482 21.912C12.8489 21.9647 12.7408 21.9943 12.6305 21.9991L12.6001 22Z" fill="white" />
      <defs>
        <radialGradient id="paint0_radial_397_6186" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(15 15) rotate(90) scale(15)">
          <stop offset="0.270833" stopColor={borderColor} />
          <stop offset={1} stopColor={color} />
        </radialGradient>
      </defs>
    </svg>
  )
}

export const CircleCloseIcon = ({ width = 55, height = 55, color = "#BF1D08", borderColor = "#EE3232", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="27.5" cy="27.5" r="20.5" fill={color} />
      <g filter="url(#filter0_f_331_29782)">
        <circle cx="27.5" cy="27.5" r="16.8824" fill={borderColor} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1909 27.5003L36.716 19.9751C37.1836 19.5076 37.1836 18.7518 36.716 18.2843C36.2485 17.8167 35.4927 17.8167 35.0252 18.2843L27.5 25.8094L19.9749 18.2843C19.5073 17.8167 18.7516 17.8167 18.284 18.2843C17.8165 18.7518 17.8165 19.5076 18.284 19.9751L25.8092 27.5003L18.284 35.0254C17.8165 35.493 17.8165 36.2487 18.284 36.7163C18.5172 36.9494 18.8233 37.0666 19.1294 37.0666C19.4356 37.0666 19.7417 36.9494 19.9749 36.7163L27.5 29.1911L35.0252 36.7163C35.2583 36.9494 35.5645 37.0666 35.8706 37.0666C36.1767 37.0666 36.4828 36.9494 36.716 36.7163C37.1836 36.2487 37.1836 35.493 36.716 35.0254L29.1909 27.5003Z"
        fill="white"
       
      />
      <defs>
        <filter id="filter0_f_331_29782" x="0.617676" y="0.617676" width="53.7646" height="53.7646" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation={5} result="effect1_foregroundBlur_331_29782" />
        </filter>
      </defs>
    </svg>
  )
}