import { useState } from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import writtenNumber from "written-number";
import { useAppContext } from "../../contexts/AppContextProvider";
import { submitEformApi } from "../../services/api.service";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { allowedInputNumberOnly, allowedInputTextOnly, formatedCurrency, showCountFormatedText } from "../../utils/helper";
import { ArrowLeftIcon } from "../../components/IconSvg";
import EformImage from "../../assets/images/eform-banner.png";

const { TextArea } = Input;

function Deposit() {
  const navigate = useNavigate();
  const { index } = useParams();
  const { showSuccessMsg, showErrorMsg } = useAppContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const bookingForm = getSessionStorage("booking_form");
  const formIdx = index ? index - 1 : 0;
  const transactions = bookingForm?.transactions || [];

  const onUpdateEforms = () => {
    transactions[formIdx].filled = true;
    saveSessionStorage("booking_form", { ...bookingForm, transactions });
  }

  const onPriceChange = (e) => {
    const value = e.target.value?.replace(/\./g, '');
    const regexPattern = /^(\s*|\d+)$/;
    if (!regexPattern.test(value)) return;

    if (!value) {
      form.setFieldValue('terbilang', '');
      return;
    };
    const numbToWords = writtenNumber(value, { lang: 'id' });
    form.setFieldValue('terbilang', `${numbToWords} rupiah`);
    form.validateFields(["terbilang"]);
  }

  const onFinish = async (values) => {
    try {
      const request = {
        prefix: transactions[formIdx].code,
        data: {
          ...values,
          jumlahSetoran: values.jumlahSetoran?.replace(/\./g, '') || ""
        }
      }
      setLoading(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      onUpdateEforms();
      navigate(-1);
      showSuccessMsg("Submit form successfully!");
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
      console.log("Error onSubmitEform:", error)
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_header">
        <ArrowLeftIcon onClick={() => navigate(-1)} />
      </div>
      <div className="flex items-center justify-between px-7 pb-4 z-[1]">
        <div className="w-1/2 text-2xl text-white font-bold">{transactions[formIdx]?.label}</div>
        <div className="max-w-[140px] -mt-5">
          <img src={EformImage} alt="banner" />
        </div>
      </div>
      <div className="eform_content">
        <Alert message="Form hanya bisa di isi sekali." type="info" showIcon />
        <div className="text-[#212B36] font-semibold mt-5">{transactions[formIdx]?.label}</div>
        <div className="text-[#637381] text-xs mb-6">Isi form dibawah ini dengan teliti.</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            string: {
              min: 'Input must be minimum ${min} character!',
              range: 'Input minimum & maximum must be ${min} character!'
            }
          }}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="Cara Penyetoran" name="caraPenyetoran" required>
            <Select
              size="large"
              placeholder="Cara Penyetoran"
              popupClassName="form-item-select"
              options={[
                { value: 'Dengan Buku Tabungan', label: 'Dengan Buku Tabungan' },
                { value: 'Tanpa Buku Tabungan', label: 'Tanpa Buku Tabungan' }
              ]}
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Nomor Rekening"
            name="nomorRekening"
            normalize={allowedInputNumberOnly}
            rules={[{ required: true, min: 15, max: 15 }]}
          >
            <Input size="large" placeholder="Nomor Rekening" maxLength={15} showCount />
          </Form.Item>
          <Form.Item className="form-item" label="Nama Penerima" name="namaPemilikRekening" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
            <Input size="large" placeholder="Nama Penerima" maxLength={80} />
          </Form.Item>
          <Form.Item className="form-item" label="Jumlah Setoran" name="jumlahSetoran" normalize={formatedCurrency} rules={[{ required: true }]}>
            <Input
              size="large"
              placeholder="Jumlah Setoran"
              maxLength={16}
              showCount={{ formatter: (info) => showCountFormatedText(info, 13) }}
              onChange={onPriceChange}
            />
          </Form.Item>
          <Form.Item className="form-item" label="Terbilang" name="terbilang" rules={[{ required: true }]}>
            <TextArea size="large" placeholder="Terbilang" readOnly autoSize />
          </Form.Item>
          <Form.Item className="form-item" label="Sumber Dana" name="sumberDana" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
            <Input size="large" placeholder="Sumber Dana" />
          </Form.Item>
          <Form.Item className="form-item" label="Nama Penyetor" name="namaPenyetor" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
            <Input size="large" placeholder="Nama Penyetor" maxLength={80} />
          </Form.Item>
          <Form.Item className="form-item" label="Nomor Handphone" name="nomorTelepon" normalize={allowedInputNumberOnly} rules={[{ required: true, min: 9 }]}>
            <Input size="large" placeholder="Nomor Handphone" maxLength={13} showCount />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" className="full-btn mt-9" loading={loading}>
            Submit eForm
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Deposit;