import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, message } from 'antd';
import { destroyToken, saveGuestToken } from "../services/jwt.service";
import LogoIcon from "../assets/icons/logo-icon.png";
import LandingImg from "../assets/images/landing-banner.png";
import MarkImg from "../assets/images/mark.png";
import { guestTokenApi } from "../services/api.service";
import { useAppContext } from './../contexts/AppContextProvider';

function Landing() {
  const navigate = useNavigate();
  const { showErrorMsg }= useAppContext();

  useEffect(() => {
    destroyToken();
  }, [])

  const loginAsGuest = async () => {
    try {
      const response = await guestTokenApi();
      if (response?.status != 200) throw response?.message;

      saveGuestToken(response?.data?.token);
      navigate('/home');
    } catch (error) {
      showErrorMsg(error);
      console.log('Error on loginAsGuest:', error);
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_logo_header">
        <img src={LogoIcon} alt="logo" />
        <span className="base_header_text">BRIQue</span>
      </div>
      <div className="base_only_banner">
        <img src={LandingImg} alt="banner" />
      </div>
      <div className="landing_form">
        <div className="landing_title">
          <div className="landing_title_welcome">Welcome to</div>
          <div className="landing_title_brique">BRIQue</div>
          <img src={MarkImg} alt="mark-circle" className="landing_title_mark_circle" />
        </div>
        <div className="landing_description">
          Transformasi Antrian Layanan dengan fitur online
        </div>
        <div className="w-full max-w-[256px] mb-5">
          <Button type="primary" size="large" className="full-btn" onClick={() => navigate('/login')}>Sign In</Button>
        </div>
        <div className="w-full max-w-[256px] mb-4">
          <Button size="large" className="full-btn" onClick={() => navigate('/register')}>Sign Up</Button>
        </div>
        <div className="flex items-center gap-1 text-sm">
          <span className="text-[#919EAB]">Or Continue</span>
          <span className="text-[#33A0FF] font-bold underline cursor-pointer" onClick={loginAsGuest}>
            as Guest
          </span>
        </div>
      </div>
    </div>
  )
}

export default Landing;