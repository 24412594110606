import { Fragment, useState } from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import writtenNumber from "written-number";
import { useAppContext } from "../../contexts/AppContextProvider";
import { submitEformApi } from "../../services/api.service";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { allowedInputNumberOnly, allowedInputTextOnly, formatedCurrency, showCountFormatedText } from "../../utils/helper";
import { ArrowLeftIcon } from "../../components/IconSvg";
import EncashmentTypeJson from "../../assets/json/encashmentType.json";
import WarkatJson from "../../assets/json/warkat.json";
import EformImage from "../../assets/images/eform-banner.png";

const { TextArea } = Input;

function CheckEncashment() {
  const navigate = useNavigate();
  const { index } = useParams();
  const { showSuccessMsg, showErrorMsg } = useAppContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const bookingForm = getSessionStorage("booking_form");
  const formIdx = index ? index - 1 : 0;
  const transactions = bookingForm?.transactions || [];

  const onUpdateEforms = () => {
    transactions[formIdx].filled = true;
    saveSessionStorage("booking_form", { ...bookingForm, transactions });
  }

  const onPriceChange = (e) => {
    const value = e.target.value?.replace(/\./g, '');
    const regexPattern = /^(\s*|\d+)$/;
    if (!regexPattern.test(value)) return;

    if (!value) {
      form.setFieldValue('terbilang', '');
      return;
    };
    const numbToWords = writtenNumber(value, { lang: 'id' });
    form.setFieldValue('terbilang', `${numbToWords} rupiah`);
    form.validateFields(["terbilang"]);
  }

  const onFinish = async (values) => {
    try {
      const request = {
        prefix: transactions[formIdx].code,
        data: values
      }
      if (request?.data?.jenisPencairan == "Tunai") {
        request.data.jumlahTarikTunai = values.jumlahTarikTunai?.replace(/\./g, '') || ""
      } else {
        request.data.jumlah = values.jumlah?.replace(/\./g, '') || ""
      }

      setLoading(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      onUpdateEforms();
      navigate(-1);
      showSuccessMsg("Submit form successfully!");
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
      console.log("Error onSubmitEform:", error)
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_header">
        <ArrowLeftIcon onClick={() => navigate(-1)} />
      </div>
      <div className="flex items-center justify-between px-7 pb-4 z-[1]">
        <div className="w-1/2 text-2xl text-white font-bold">{transactions[formIdx]?.label}</div>
        <div className="max-w-[140px] -mt-5">
          <img src={EformImage} alt="banner" />
        </div>
      </div>
      <div className="eform_content">
        <Alert message="Form hanya bisa di isi sekali." type="info" showIcon />
        <div className="text-[#212B36] font-semibold mt-5">{transactions[formIdx]?.label}</div>
        <div className="text-[#637381] text-xs mb-6">Isi form dibawah ini dengan teliti.</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            string: {
              min: 'Input must be minimum ${min} character!',
              range: 'Input minimum & maximum must be ${min} character!'
            }
          }}
          onFinish={onFinish}
        >
          <Form.Item className="form-item" label="Jenis Pencairan" name="jenisPencairan" rules={[{ required: true }]}>
            <Select
              size="large"
              placeholder="Jenis Pencairan"
              popupClassName="form-item-select"
              options={EncashmentTypeJson}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.jenisPencairan !== next.jenisPencairan}>
            {({ getFieldValue }) => {
              if (getFieldValue('jenisPencairan') === "Tunai") {
                return (
                  <Fragment>
                    <Form.Item
                      className="form-item"
                      label="Nomor Rekening"
                      name="nomorRekening"
                      normalize={allowedInputNumberOnly}
                      rules={[{ required: true, min: 15, max: 15 }]}
                    >
                      <Input size="large" placeholder="Nomor Rekening" maxLength={15} showCount />
                    </Form.Item>

                    <Form.Item className="form-item" label="Jumlah Penarikan" name="jumlahTarikTunai" normalize={formatedCurrency} rules={[{ required: true }]}>
                      <Input
                        size="large"
                        placeholder="Jumlah Penarikan"
                        maxLength={16}
                        showCount={{ formatter: (info) => showCountFormatedText(info, 13) }}
                        onChange={onPriceChange}
                      />
                    </Form.Item>
                    <Form.Item className="form-item" label="Nama Pemilik Rekening" name="namaPemilikRekening" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                      <Input size="large" placeholder="Nama Pemilik Rekening" maxLength={80} />
                    </Form.Item>
                    <Form.Item className="form-item" label="Terbilang" name="terbilang" rules={[{ required: true }]}>
                      <TextArea size="large" placeholder="Terbilang" readOnly autoSize />
                    </Form.Item>
                    <Form.Item className="form-item" label="Nomor Telepon" name="nomorTelepon" normalize={allowedInputNumberOnly} rules={[{ required: true, min: 9 }]}>
                      <Input size="large" placeholder="Nomor Telepon" maxLength={13} showCount />
                    </Form.Item>
                    <Form.Item className="form-item" label="Jenis Warkat" name="jenisWarkat" rules={[{ required: true }]}>
                      <Select
                        size="large"
                        placeholder="Jenis Warkat"
                        popupClassName="form-item-select"
                        options={WarkatJson?.[getFieldValue('jenisPencairan')] || []}
                      />
                    </Form.Item>
                  </Fragment>
                )
              }
              return (
                <Fragment>
                  <Form.Item
                    className="form-item"
                    label="Nomor Rekening Asal"
                    name="nomorRekeningAsal"
                    normalize={allowedInputNumberOnly}
                    rules={[{ required: true, min: 15, max: 15 }]}
                  >
                    <Input size="large" placeholder="Nomor Rekening Asal" maxLength={15} showCount />
                  </Form.Item>
                  <Form.Item className="form-item" label="Nama Pemilik Rekening Asal" name="namaPemilikRekeningAsal" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Nama Pemilik Rekening Asal" maxLength={80} />
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="Nomor Rekening Tujuan"
                    name="nomorRekeningTujuan"
                    normalize={allowedInputNumberOnly}
                    rules={[{ required: true, min: 15, max: 15 }]}
                  >
                    <Input size="large" placeholder="Nomor Rekening Tujuan" maxLength={15} showCount />
                  </Form.Item>
                  <Form.Item className="form-item" label="Nama Pemilik Rekening Tujuan" name="namaPemilikRekeningTujuan" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
                    <Input size="large" placeholder="Nama Pemilik Rekening Tujuan" maxLength={80} />
                  </Form.Item>
                  <Form.Item className="form-item" label="Jumlah Dana" name="jumlah" normalize={formatedCurrency} rules={[{ required: true }]}>
                    <Input
                      size="large"
                      placeholder="Jumlah Dana"
                      maxLength={16}
                      showCount={{ formatter: (info) => showCountFormatedText(info, 13) }}
                      onChange={onPriceChange}
                    />
                  </Form.Item>
                  <Form.Item className="form-item" label="Terbilang" name="terbilang" rules={[{ required: true }]}>
                    <TextArea size="large" placeholder="Terbilang" readOnly autoSize />
                  </Form.Item>
                  <Form.Item className="form-item" label="Jenis Warkat" name="jenisWarkat" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      placeholder="Jenis Warkat"
                      popupClassName="form-item-select"
                      options={WarkatJson?.[getFieldValue('jenisPencairan')] || []}
                    />
                  </Form.Item>
                </Fragment>
              )
            }}
          </Form.Item>

          <Form.Item noStyle shouldUpdate={(prev, next) => prev.jenisWarkat !== next.jenisWarkat}>
            {({ getFieldValue }) => getFieldValue('jenisWarkat') !== "Receipt" && (
              <Form.Item className="form-item" label="No. Warkat" name="nomorWarkat" normalize={allowedInputNumberOnly} rules={[{ required: true, min: 6 }]}>
                <Input size="large" placeholder="No. Warkat" maxLength={9} showCount />
              </Form.Item>
            )}
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" className="full-btn mt-9" loading={loading}>
            Submit eForm
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default CheckEncashment;