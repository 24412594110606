import { useState } from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContextProvider";
import { submitEformApi } from "../../services/api.service";
import { getSessionStorage, saveSessionStorage } from '../../services/jwt.service';
import { ArrowLeftIcon } from "../../components/IconSvg";
import EformImage from "../../assets/images/eform-banner.png";
import { allowedInputNumberOnly, allowedInputTextOnly, formatedCurrency, showCountFormatedText } from "../../utils/helper";

function DepositoWithdrawal() {
  const navigate = useNavigate();
  const { index } = useParams();
  const { showSuccessMsg, showErrorMsg } = useAppContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const bookingForm = getSessionStorage("booking_form");
  const formIdx = index ? index - 1 : 0;
  const transactions = bookingForm?.transactions || [];

  const onUpdateEforms = () => {
    transactions[formIdx].filled = true;
    saveSessionStorage("booking_form", { ...bookingForm, transactions });
  }

  const onFinish = async (values) => {
    try {
      const request = {
        prefix: transactions[formIdx].code,
        data: {
          ...values,
          jumlahKredit: values?.jumlahKredit?.replace(/\./g, '') || ""
        }
      }
      setLoading(true);
      const response = await submitEformApi(request);
      if (response?.status != 200) throw response?.message;

      setLoading(false);
      onUpdateEforms();
      navigate(-1);
      showSuccessMsg("Submit form successfully!");
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
      console.log("Error onSubmitEform:", error)
    }
  }

  return (
    <div className="base_container">
      <div className="base_back_header">
        <ArrowLeftIcon onClick={() => navigate(-1)} />
      </div>
      <div className="flex items-center justify-between px-7 pb-4 z-[1]">
        <div className="w-1/2 text-2xl text-white font-bold">{transactions[formIdx]?.label}</div>
        <div className="max-w-[140px] -mt-5">
          <img src={EformImage} alt="banner" />
        </div>
      </div>
      <div className="eform_content">
        <Alert message="Form hanya bisa di isi sekali." type="info" showIcon />
        <div className="text-[#212B36] font-semibold mt-5">{transactions[formIdx]?.label}</div>
        <div className="text-[#637381] text-xs mb-6">Isi form dibawah ini dengan teliti.</div>
        <Form
          size="small"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          validateMessages={{
            required: '${label} is required!',
            string: {
              min: 'Input must be minimum ${min} character!',
              range: 'Input minimum & maximum must be ${min} character!'
            }
          }}
          onFinish={onFinish}
        >
          <Form.Item
            className="form-item"
            label="Nomor Rekening Deposito"
            name="nomorRekening"
            normalize={allowedInputNumberOnly}
            rules={[{ required: true, min: 15, max: 15 }]}
          >
            <Input size="large" placeholder="Nomor Rekening Deposito" maxLength={15} showCount />
          </Form.Item>
          <Form.Item className="form-item" label="Nama Nasabah" name="namaPemilikRekeningAsal" normalize={allowedInputTextOnly} rules={[{ required: true }]}>
            <Input size="large" placeholder="Nama Nasabah" maxLength={80} />
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Nomor Rekening Tujuan"
            name="nomorRekeningTujuan"
            normalize={allowedInputNumberOnly}
            rules={[{ required: true, min: 15, max: 15 }]}
          >
            <Input size="large" placeholder="Nomor Rekening Tujuan" maxLength={15} showCount />
          </Form.Item>
          <Form.Item className="form-item" label="Jenis Penarikan Deposito" name="jenisPenarikanDeposito" rules={[{ required: true }]}>
            <Select
              size="large"
              placeholder="Jenis Penarikan Deposito"
              popupClassName="form-item-select"
              options={[
                { value: 'Penuh', label: 'Penuh' },
                { value: 'Sebagian', label: 'Sebagian' }
              ]}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.jenisPenarikanDeposito !== next.jenisPenarikanDeposito}>
            {({ getFieldValue }) => getFieldValue('jenisPenarikanDeposito')?.includes("Sebagian") && (
              <Form.Item className="form-item" label="Jumlah Penarikan" name="jumlahKredit" normalize={formatedCurrency} rules={[{ required: true }]}>
                <Input
                  size="large"
                  placeholder="Jumlah Penarikan"
                  maxLength={16}
                  showCount={{ formatter: (info) => showCountFormatedText(info, 13) }}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item className="form-item" label="No. Bilyet" name="noBilyet" normalize={allowedInputNumberOnly} rules={[{ required: true }]}>
            <Input size="large" placeholder="No. Bilyet" maxLength={12} showCount />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" className="full-btn mt-9" loading={loading}>
            Submit eForm
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default DepositoWithdrawal;