import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'antd';
import { RightOutlined, CameraOutlined } from '@ant-design/icons';
import { useAppContext } from "../../contexts/AppContextProvider";
import { logoutApi } from "../../services/api.service";
import { destroyToken, getSessionStorage, getToken } from "../../services/jwt.service";
import { getInitialName } from "../../utils/helper";
import { HomeIcon, ClockIcon, UserIcon } from "../../components/IconSvg";
import NotFoundImage from "../../assets/images/not-found-image.png";

function Profile() {
  const navigate = useNavigate();
  const { showSuccessMsg, showWarningMsg, showErrorMsg } = useAppContext();
  const [open, setOpen] = useState();
  const [loading, setLoading] = useState(false);
  const isAuthenticated = !!getToken();
  const userDetail = getSessionStorage('user_detail');

  const onLogout = async () => {
    try {
      setLoading(true);
      const response = await logoutApi();
      if (response.status != 200) throw response?.message;

      setLoading(false);
      destroyToken();
      showSuccessMsg(response?.message);
      navigate('/login');
    } catch (error) {
      setLoading(false);
      showErrorMsg(error);
      console.log("Error onLogout:", error);
    }
  }

  return (
    <div className="base_container">
      <div className="text-white text-2xl text-center font-bold mt-14 mb-10">Profile</div>
      {!isAuthenticated &&
        <div className="profile_content items-center justify-center mt-6">
          <div className="max-w-[240px]">
            <img src={NotFoundImage} alt="notfound" />
          </div>
          <div className="max-w-[258px] text-[#212B36] text-sm text-center font-semibold my-6">
            Login atau Registrasi terlebih dahulu untuk menampilkan History
          </div>
          <div className="w-full max-w-[313px]">
            <Button type="primary" size="large" className="full-btn" onClick={() => navigate("/login")}>Login/Register</Button>
          </div>
        </div>
      }
      {isAuthenticated &&
        <Fragment>
          <div className="relative w-20 h-20 mx-auto bg-[#CCE7FF] rounded-full text-5xl font-bold mb-4">
            <div className="flex items-center justify-center w-20 h-20 rounded-full text-2xl text-[#00529C]">
              {getInitialName(userDetail.name)}
            </div>
            <div className="absolute -bottom-[1px] -right-[1px] flex bg-white p-2 rounded-full drop-shadow">
              <CameraOutlined style={{ fontSize: 12, color: '#00529C' }} />
            </div>
          </div>
          <div className="text-white text-center">
            <div className="text-xl font-medium capitalize">{userDetail.name}</div>
            <div className="text-base">{userDetail.email}</div>
          </div>
          <div className="profile_content gap-10 mt-10">
            <div onClick={() => showWarningMsg('Page is undermaintenance.')}>
              <div className="flex items-center justify-between">
                <div className="text-[#212B36] font-semibold">Account</div>
                <RightOutlined />
              </div>
              <div className="text-[#637381] text-xs">Change Your Account Profile</div>
            </div>
            <div onClick={() => navigate('change-password')}>
              <div className="flex items-center justify-between">
                <div className="text-[#212B36] font-semibold">Password</div>
                <RightOutlined />
              </div>
              <div className="text-[#637381] text-xs">Change Your Password</div>
            </div>
            <div onClick={() => setOpen(true)}>
              <div className="flex items-center justify-between">
                <div className="text-[#212B36] font-semibold">Sign Out</div>
                <RightOutlined />
              </div>
              <div className="text-[#637381] text-xs">Sign Out Your Account</div>
            </div>
          </div>
        </Fragment>
      }
      <div className="history_footer">
        <div className="cursor-pointer" onClick={() => navigate("/home")}>
          <HomeIcon />
          <div>Home</div>
        </div>
        <div className="cursor-pointer" onClick={() => navigate("/history")}>
          <ClockIcon />
          <div>History</div>
        </div>
        <div className="text-[#00529C] cursor-pointer" onClick={() => navigate("/profile")}>
          <UserIcon color="#00529C" />
          <div>Profile</div>
        </div>
      </div>
      <Modal
        open={open}
        width={267}
        closeIcon={null}
        title={null}
        footer={null}
        centered
      >
        <div className="flex flex-col items-center">
          <div className="text-[#191919] text-sm font-semibold mt-4 mb-10">Are you sure want to sign out?</div>
          <div className="flex items-center gap-3 mb-4">
            <Button type="primary" loading={loading} onClick={onLogout}>Yes</Button>
            <Button className="orange-btn" onClick={() => setOpen(false)}>No</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Profile;