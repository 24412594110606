import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Camera from "react-webcam";
import { saveSessionStorage } from "../../services/jwt.service";
import { ArrowLeftIcon } from "../../components/IconSvg";

function WebCamera() {
  const navigate = useNavigate();
  const { id } = useParams();
  const webcamRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight - 250);
  const [photo, setPhoto] = useState("");

  useEffect(() => {
    const updateScreenDimensions = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight - 250);
    };

    window.addEventListener('resize', updateScreenDimensions);
    return () => {
      window.removeEventListener('resize', updateScreenDimensions);
    };
  }, []);

  const onCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
  }, [webcamRef]);

  const onTakePhoto = () => {
    navigate(`/qrcode/${id}`);
    saveSessionStorage("photo_checkin", photo);
  }

  if (photo) {
    return (
      <div className="relative bg-black flex justify-center items-center" style={{ height: '100dvh' }}>
        <img className="h-fit" src={photo} />
        <div className="absolute bottom-0 w-full flex justify-between p-14">
          <div className="text-white text-lg" onClick={() => setPhoto("")}>Retake</div>
          <div className="text-white text-lg" onClick={onTakePhoto}>Use Photo</div>
        </div>
      </div>
    )
  }

  return (
    <div className="relative flex items-center bg-black" style={{ height: '100dvh' }}>
      <Camera
        mirrored
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          width: screenWidth,
          height: screenHeight,
          facingMode: "user"
        }}
      />
      <div className="absolute top-10 left-6">
        <ArrowLeftIcon width={36} height={36} onClick={() => navigate(-1)} />
      </div>
      <div className="absolute bottom-6 left-1/2 -translate-x-1/2" onClick={onCapture}>
        <svg width={70} height={70} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx={35} cy={35} r={35} fill="white" />
          <circle cx={35} cy={35} r={28} fill="white" stroke="black" strokeWidth={2} />
        </svg>
      </div>
    </div>
  )
}

export default WebCamera;